import React from 'react';
import Modal from './Modal';

import image1 from '../images/gallery/img1.jpeg' 
import image2 from '../images/gallery/img2.jpeg' 
import image3 from '../images/gallery/img3.jpeg' 
import image4 from '../images/gallery/img4.jpeg' 
import image5 from '../images/gallery/img5.jpeg' 
import image6 from '../images/gallery/img6.jpeg' 
import image7 from '../images/gallery/img7.jpeg' 
import image8 from '../images/gallery/img8.jpeg' 
import image9 from '../images/gallery/img9.jpeg' 
import image10 from '../images/gallery/img10.jpeg' 
import image11 from '../images/gallery/img11.jpeg' 
import image12 from '../images/gallery/img12.jpeg' 

class ImageGallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      selectedImageIndex: 0,
      imageWidth: 'auto',
      imageHeight: 'auto'
    };
  }

  handleImageClick = (index) => {
    const image = this.images[index];
    this.setState({ 
      showModal: true, 
      selectedImageIndex: index,
      imageWidth: 'auto',  // Sabit bir genişlik ve yükseklik belirtebilirsiniz.
      imageHeight: 'auto'
    });
  }

  handleCloseModal = () => {
    this.setState({ showModal: false });
  }

  handlePrevImage = () => {
    this.setState((prevState) => {
      const newIndex = (prevState.selectedImageIndex - 1 + this.images.length) % this.images.length;
      return {
        selectedImageIndex: newIndex
      };
    });
  }

  handleNextImage = () => {
    this.setState((prevState) => {
      const newIndex = (prevState.selectedImageIndex + 1) % this.images.length;
      return {
        selectedImageIndex: newIndex
      };
    });
  }

  images = [
    { src: image1  },
    { src: image2 },  // width:"600px", height:"600px"
    { src: image3 },
    { src: image4 },
    { src: image5 },
    { src: image6 },
    { src: image7 },
    { src: image8 },
    { src: image9},
    { src: image10 },
    { src: image11},
    { src: image12 },
  ];

  render() {
    const selectedImage = this.images[this.state.selectedImageIndex];

    return (
      <div>
        <div className='grid grid-cols-4 gap-[20px] max-[950px]:grid-cols-3 max-[780px]:grid-cols-2 max-[550px]:grid-cols-1' style={galleryStyles.imageContainer}>
          {this.images.map((image, index) => (
            <img
              className='w-full h-[275px]'
              key={index}
              src={image.src}
              alt={`Thumbnail ${index}`}
              style={galleryStyles.thumbnail}
              onClick={() => this.handleImageClick(index)}
            />
          ))}
        </div>

        <Modal 
          show={this.state.showModal} 
          onClose={this.handleCloseModal} 
          imgSrc={selectedImage.src} 
          width={this.state.imageWidth}
          height={this.state.imageHeight}
          onPrev={this.handlePrevImage}
          onNext={this.handleNextImage}
        />
      </div>
    );
  }
}

const galleryStyles = {
//   imageContainer: {
//     display: 'grid',
//     justifyContent: 'center',
//     gap: '10px'
//   },
  thumbnail: {
    cursor: 'pointer',
    
  }
};

export default ImageGallery;