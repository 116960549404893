import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import { connect } from 'react-redux'
import Card1 from '../components/cards/Card1';
import { getAcceptedStudent, getEnglishExamResults, getMainInfo } from '../actions/MainAction';
import StudentReviews from '../components/StudentReviews';
import Card27 from '../components/cards/Card27';
import LoadMoreBtn from '../components/LoadMoreBtn';
import Card20 from '../components/cards/Card20';
import { Helmet } from 'react-helmet';

export class AllSchools extends Component {


  componentDidMount() {
    // localStorage'dan dil bilgisi al
    const savedLanguage = localStorage.getItem('language');

    // Eğer kaydedilmiş bir dil varsa, onu kullan
    if (savedLanguage) {
      this.props.getMainInfo(savedLanguage); // savedLanguage'yi lang_id olarak kullan
    } else {
      this.props.getMainInfo(this.props.lang_id); // varsayılan lang_id
    }

    this.props.getEnglishExamResults(0.4, "");
    this.props.getAcceptedStudent(0.4, "", "")
    window.scrollTo(0, 0);
  }

  loadMoreExamResults = (page) => {
    const { getEnglishExamResults } = this.props;
    getEnglishExamResults(0.4 * page, "")
  }

  loadMore = (page) => {
    this.props.getAcceptedStudent(0.4 * page)
  }


  render() {
    const { contentData, mainInfoData, englishExamResults, acceptedStudentsData } = this.props;
    // console.log(acceptedStudentsData);
    return (
      <div >
        {
          contentData?.sh_schools ?
            <Helmet>
              <title>{contentData?.sh_schools} | CELT Colleges</title>
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={`${contentData?.sh_schools} | CELT Colleges`} />
              <meta property="og:description" content={"CELT Colleges-də müxtəlif təhsil pillələrində məktəblərimiz mövcuddur: High School, Secondary School və Primary School. "} />
              <meta name="description" content={"CELT Colleges-də müxtəlif təhsil pillələrində məktəblərimiz mövcuddur: High School, Secondary School və Primary School. "} />
              <meta name="keywords" content={"məktəblər, CELT Colleges məktəbləri, High School, Secondary School, Primary School, məktəb təhsili, təhsil proqramları, CELT məktəbləri"} />
              <meta property="og:url" content={`https://celt.az${window.location.pathname}`} />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
              <meta property="og:image:width" content="900" />
              <meta property="og:image:height" content="600" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={`${contentData?.sh_schools} | CELT Colleges`} />
              <meta name="twitter:description" content={"CELT Colleges-də müxtəlif təhsil pillələrində məktəblərimiz mövcuddur: High School, Secondary School və Primary School. "} />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="7 minutes" />
            </Helmet> : null
        }

        <div className='container'>
          <SectionHeader title={contentData?.sh_schools} />

          <div className='grid grid-cols-3 gap-[32px] justify-center max-[1100px]:grid-cols-3 max-[1100px]:gap-[26px] max-[800px]:grid-cols-2 max-[550px]:grid-cols-1'>
            <Card1
              // key={i}
              image={mainInfoData.filter(x => x.section_id == 1)[0]?.image_full_url}
              path={mainInfoData.filter(x => x.section_id == 1)[0]?.path ? mainInfoData.filter(x => x.section_id == 1)[0]?.path : `/main-page/${mainInfoData.filter(x => x.section_id == 1)[0]?.id}`}
              title={mainInfoData.filter(x => x.section_id == 1)[0]?.translate?.title}
              text={mainInfoData.filter(x => x.section_id == 1)[0]?.translate?.text}
              btn={mainInfoData.filter(x => x.section_id == 1)[0]?.translate?.btn}
              btntype='1'

            />
            <Card1
              // key={i}
              image={mainInfoData.filter(x => x.section_id == 1)[2]?.image_full_url}
              path={mainInfoData.filter(x => x.section_id == 1)[2]?.path ? mainInfoData.filter(x => x.section_id == 1)[2]?.path : `/main-page/${mainInfoData.filter(x => x.section_id == 1)[2]?.id}`}
              title={mainInfoData.filter(x => x.section_id == 1)[2]?.translate?.title}
              text={mainInfoData.filter(x => x.section_id == 1)[2]?.translate?.text}
              btn={mainInfoData.filter(x => x.section_id == 1)[2]?.translate?.btn}
              btntype='1'

            />

            <Card1
              // key={i}
              image={mainInfoData.filter(x => x.section_id == 1)[1]?.image_full_url}
              path={mainInfoData.filter(x => x.section_id == 1)[1]?.path ? mainInfoData.filter(x => x.section_id == 1)[1]?.path : `/main-page/${mainInfoData.filter(x => x.section_id == 1)[1]?.id}`}
              title={mainInfoData.filter(x => x.section_id == 1)[1]?.translate?.title}
              text={mainInfoData.filter(x => x.section_id == 1)[1]?.translate?.text}
              btn={mainInfoData.filter(x => x.section_id == 1)[1]?.translate?.btn}
              btntype='1'

            />
          </div>
        </div>

        <section className='mt-[52px]'>
          <div className='container'>

            <SectionHeader title={contentData?.sh_our_results} />

            <div className=' grid grid-cols-4 gap-[16px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>

              {
                englishExamResults?.map((data, i) => {
                  return (
                    <Card27
                      key={i}
                      first_name={data?.student?.first_name}
                      full_name={data?.student?.full_name}
                      last_name={data?.student?.last_name}
                      title={data?.exam?.lesson?.title}
                      score={data?.score}
                      company={data?.student?.company?.name}
                      company_id={data?.student?.company_id}
                      date={data?.created_at}
                      image_full_url={data?.student?.avatar_full_url}
                      url={data?.certificate?.certificate_full_url}

                    />
                  )
                })
              }
            </div>

            {
              englishExamResults?.length >= 8 ?
                <LoadMoreBtn onLoadData={this.loadMoreExamResults} defCount={8} count={englishExamResults?.length} /> : null
            }

          </div>

        </section>


        <section className='mt-[52px]'>
          <SectionHeader title={contentData?.admitted_student} />

          <div className='container grid grid-cols-4 gap-[8px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
            {
              acceptedStudentsData?.map((data, i) => {
                return (
                  <Card20
                    key={i}
                    first_name={data?.first_name}
                    last_name={data?.last_name}
                    company_name={data?.company_name}
                    image_full_url={data?.inst_image?.image_full_url}
                    country_image={data?.country_image}
                    country_name={data?.country_name}
                    user_image={data?.user_image}
                    program_name={data?.program_name}
                    inst_name={data?.inst_name}
                    ranking={data?.ranking}
                    company_id={data?.company_id}

                  />
                )
              })
            }
          </div>
          {
            acceptedStudentsData?.length >= 8 ?
              <LoadMoreBtn onLoadData={this.loadMore} defCount={8} count={acceptedStudentsData?.length} /> : null
          }
        </section>


        <section className='mt-[52px] bg-[#f9fbfb]  pb-[52px]'>
          <SectionHeader title={contentData?.students_say} />
          <StudentReviews grid_cols='grid-cols-3' />
        </section>
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  mainInfoData: state.Data.mainInfoData,
  contentData: state.Data.contentData,
  englishExamResults: state.Data.englishExamResults,
  acceptedStudentsData: state.Data.acceptedStudentsData,
  lang_id: state.Data.lang_id,
})

const mapDispatchToProps = { getMainInfo, getEnglishExamResults, getAcceptedStudent }
export default connect(mapStateToProps, mapDispatchToProps)(AllSchools)