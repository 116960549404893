import React, { Component } from 'react'

export class DowloadSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={28}
    viewBox="0 0 24 24"
        
  >
 
    <path
      fill="var(--blue)"
      fillRule="evenodd"
      d="m13 13.175 3.243-3.242 1.414 1.414L12 17.004l-5.657-5.657 1.414-1.414L11 13.175V2h2v11.175ZM4 16h2v4h12v-4h2v4c0 1.1-.9 2-2 2H6c-1.1 0-2-.963-2-2v-4Z"
    />
  </svg>
    )
  }
}

export default DowloadSvg