import React, { Component } from 'react'

export class CloseSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={38}
      height={38}
      fill="none"

    >
      <circle cx={19} cy={19} r={19} fill="#fff" />
      <path
        fill="#264EA9"
        d="m24.082 14.582-4.12 4.121 4.12 4.12a.892.892 0 1 1-1.258 1.26l-4.12-4.121-4.122 4.12a.89.89 0 0 1-1.258-1.258l4.12-4.12-4.12-4.122a.89.89 0 0 1 1.258-1.258l4.121 4.12 4.12-4.12a.89.89 0 0 1 1.26 1.258Zm9.762 4.121a15.14 15.14 0 1 1-15.14-15.14 15.159 15.159 0 0 1 15.14 15.14Zm-1.782 0a13.359 13.359 0 1 0-13.359 13.36 13.374 13.374 0 0 0 13.36-13.36Z"
      />
    </svg>
    )
  }
}

export default CloseSvg