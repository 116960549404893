import React, { Component } from 'react'

export class SubjectSvg1 extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={15}
        height={16}
        fill="none"
      >
        <path
          fill="#264EA9"
          d="M.559 15.381V.628c.02-.017.05-.03.06-.053A.913.913 0 0 1 1.13.07c.033-.014.058-.045.087-.069h8.768c.003.014.002.035.01.042.24.202.487.395.72.603 1.142 1.019 2.28 2.04 3.416 3.065.212.19.408.399.612.6V15.38c-.02.016-.052.027-.06.047a.906.906 0 0 1-.533.518c-.026.01-.044.042-.065.064H1.217c-.031-.027-.058-.063-.094-.078a.906.906 0 0 1-.505-.502c-.009-.02-.04-.032-.06-.049Zm12.99-.604V4.968h-.183c-1.007 0-2.014.002-3.022 0-.578-.003-.952-.382-.952-.958V1.251c-.056-.003-.1-.008-.143-.008l-7.21-.008c-.14 0-.21.07-.21.21v13.12c0 .03.002.06-.001.09-.01.087.022.133.116.123.035-.004.07 0 .105 0h11.5Zm-3.1-13.252c-.013.026-.02.034-.02.042-.007.743-.01 1.484-.018 2.227-.002.102.05.11.13.11l2.465-.001c.024 0 .048-.013.082-.022l-2.639-2.355Z"
        />
        <path
          fill="#264EA9"
          d="M12.258 5.55v1.21H8.586V5.55h3.672ZM8.582 9.841V8.628h3.673v1.213H8.582ZM12.257 11.71v1.208H8.586v-1.207h3.671ZM7.552 11.475 4.405 13.62c-.453-.331-.9-.656-1.345-.985a.14.14 0 0 1-.05-.097c-.004-.299-.002-.598-.002-.93.067.046.114.077.159.11.378.276.758.55 1.132.831.081.061.14.06.225.012.649-.36 1.3-.715 1.95-1.071.039-.021.082-.047.123-.048.313-.004.626-.002.94-.002l.015.034ZM4.405 7.117c-.447-.326-.89-.648-1.33-.974a.177.177 0 0 1-.064-.121c-.005-.294-.003-.588-.003-.917.069.047.12.08.168.115.37.272.74.541 1.107.816.087.066.152.07.25.015.645-.359 1.292-.71 1.94-1.061a.32.32 0 0 1 .137-.05 8.26 8.26 0 0 1 .943.03L4.405 7.118ZM3.01 8.352c.435.319.84.607 1.235.908.115.088.196.099.326.025.624-.352 1.255-.692 1.886-1.032a.48.48 0 0 1 .21-.055c.289-.007.578-.003.866-.003l.042.049c-.028.007-.06.007-.083.023-.988.672-1.976 1.344-2.96 2.02-.092.063-.153.07-.247 0-.39-.295-.788-.577-1.178-.87a.266.266 0 0 1-.093-.18c-.01-.282-.005-.564-.005-.884Z"
        />
      </svg>
    )
  }
}

export default SubjectSvg1