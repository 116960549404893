import * as React from "react"
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="none"
    viewBox="0 0 24 24"
  
  >
    <g stroke="var(--blue)" strokeWidth={1.5}>
      <circle cx={12} cy={16} r={3} />
      <path d="m12 19.26-2.263 2.17c-.324.31-.486.465-.623.52-.313.122-.66.017-.824-.25-.072-.117-.095-.328-.14-.75-.025-.239-.038-.358-.077-.458a.827.827 0 0 0-.5-.48c-.104-.037-.229-.049-.477-.073-.44-.044-.661-.065-.783-.134a.617.617 0 0 1-.26-.79c.056-.132.218-.287.542-.598L8.073 17l1.04-1.04M12 19.26l2.263 2.17c.324.31.486.465.623.52.313.122.66.017.824-.25.072-.117.095-.328.14-.75.025-.239.038-.358.077-.458a.828.828 0 0 1 .5-.48c.105-.037.229-.049.477-.073.44-.043.661-.065.783-.134a.617.617 0 0 0 .26-.79c-.056-.132-.218-.287-.542-.598L15.926 17 15 16.073" />
      <path
        strokeLinecap="round"
        d="M17.32 17.996c1.972-.021 3.072-.145 3.801-.875C22 16.243 22 14.828 22 12V9M7 17.998c-2.175-.012-3.353-.108-4.121-.877C2 16.243 2 14.828 2 12V8c0-2.828 0-4.243.879-5.121C3.757 2 5.172 2 8 2h8c2.828 0 4.243 0 5.121.879.49.49.707 1.146.803 2.121M9 6h6M7 9.5h2m8 0h-4.5"
      />
    </g>
  </svg>
)
export default SvgComponent
