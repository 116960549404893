import React, { Component } from 'react'

export class DateSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        data-name="Layer 2"
        viewBox="0 0 35 35"
        fill='#545454'
      
      >
        <path d="M29.545 34.75H5.455a5.211 5.211 0 0 1-5.2-5.2V8.56a5.21 5.21 0 0 1 5.205-5.2h24.09a5.21 5.21 0 0 1 5.2 5.205v20.98a5.211 5.211 0 0 1-5.205 5.205ZM5.455 5.855A2.708 2.708 0 0 0 2.75 8.56v20.985a2.709 2.709 0 0 0 2.705 2.7h24.09a2.708 2.708 0 0 0 2.7-2.7V8.56a2.707 2.707 0 0 0-2.7-2.7Z" />
        <path d="M33.5 17.331H1.541a1.25 1.25 0 0 1 0-2.5H33.5a1.25 1.25 0 0 1 0 2.5ZM9.459 9.155a1.249 1.249 0 0 1-1.25-1.25V1.5a1.25 1.25 0 0 1 2.5 0v6.405a1.25 1.25 0 0 1-1.25 1.25ZM25.542 9.155a1.249 1.249 0 0 1-1.25-1.25V1.5a1.25 1.25 0 0 1 2.5 0v6.405a1.25 1.25 0 0 1-1.25 1.25Z" />
      </svg>
    )
  }
}

export default DateSvg