import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import Card19 from '../components/cards/Card19'
import { connect } from 'react-redux';
import { getGraduats, getKidsEnglishType, } from '../actions/MainAction';
import { Link } from 'react-router-dom';
import Card13 from '../components/cards/Card13';
import Card29 from '../components/cards/Card29';
import { Helmet } from 'react-helmet';

export class SingleSchools extends Component {



  componentDidMount() {
    // localStorage'dan dil bilgisi al
    const savedLanguage = localStorage.getItem('language');
    const langId = savedLanguage ? savedLanguage : this.props.lang_id; // Eğer kaydedilmiş dil varsa onu kullan

    // Mezunlar için verileri al
    this.props.getGraduats(0.4);

    // Kids English için dil ve okul tipine göre verileri al
    this.props.getKidsEnglishType(langId, this.props.schoolType);
    window.scrollTo(0, 0);
  }

  loadMore = (page) => {
    this.props.getAcceptedStudent(page)
  }


  getLangCode = () => {
    const langId = localStorage.getItem('language'); // Dili buradan alıyoruz
    return langId === '1' ? '' : '/en';
  };
  render() {

    const { graduatsData, contentData, kidsEnglishTypeData, faqs = [] } = this.props;
    const langCode = this.getLangCode(); // Dinamik dil kodunu al

    // console.log(contentData);
    return (
      <div className=' mt-0'>
        {
          this.props.schoolType == 1 ?
            <Helmet>
              <title>High School | CELT Colleges</title>
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={`High School | CELT Colleges`} />
              <meta property="og:description" content={"Dünyanın istənilən universiteinə CELT High School diplomu və qarantiyası ilə qəbul olun."} />
              <meta name="description" content={"Dünyanın istənilən universiteinə CELT High School diplomu və qarantiyası ilə qəbul olun."} />
              <meta name="keywords" content={"CELT High School, universitetə qəbul, beynəlxalq diplom, xaricdə təhsil, universitet qarantiyası, CELT təhsil, orta məktəb diplomu, CELT High School diplomu, xaricdə universitetə qəbul"} />
              <meta property="og:url" content={`https://celt.az${window.location.pathname}`} />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
              <meta property="og:image:width" content="900" />
              <meta property="og:image:height" content="600" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={`High School | CELT Colleges`} />
              <meta name="twitter:description" content={"Dünyanın istənilən universiteinə CELT High School diplomu və qarantiyası ilə qəbul olun."} />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="7 minutes" />
            </Helmet>
            : null
        }
        {
          this.props.schoolType == 2 ?
            <Helmet>
              <title>Primary School | CELT Colleges</title>
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={`Primary School | CELT Colleges`} />
              <meta property="og:description" content={"Övladlarınızın ilk təhsilini beynəlxalq olmasını istəyirsinizsə, CELT Primary ilə başlayın."} />
              <meta name="description" content={"Övladlarınızın ilk təhsilini beynəlxalq olmasını istəyirsinizsə, CELT Primary ilə başlayın."} />
              <meta name="keywords" content={"CELT Primary, beynəlxalq təhsil, ilk təhsil, övladlar üçün təhsil, uşaqlar üçün məktəb, beynəlxalq məktəb, CELT təhsil, uşaq təhsili, tədris proqramı"} />
              <meta property="og:url" content={`https://celt.az${window.location.pathname}`} />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
              <meta property="og:image:width" content="900" />
              <meta property="og:image:height" content="600" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={`Primary School | CELT Colleges`} />
              <meta name="twitter:description" content={"Övladlarınızın ilk təhsilini beynəlxalq olmasını istəyirsinizsə, CELT Primary ilə başlayın."} />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="7 minutes" />
            </Helmet>
            : null
        }
        {
          this.props.schoolType == 3 ?
            <Helmet>
              <title>Secondary School | CELT Colleges</title>
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={`Secondary School | CELT Colleges`} />
              <meta property="og:description" content={"Övladlarınızın ilk təhsilini beynəlxalq səviyyədə təmin etmək istəyirsinizsə, CELT Secondary School sizin üçün ən doğru seçimdir. CELT Secondary School-də beynəlxalq standartlara uyğun təhsil proqramları ilə övladlarınız güclü bir təhsil təməli əldə edəcək."} />
              <meta name="description" content={"Övladlarınızın ilk təhsilini beynəlxalq səviyyədə təmin etmək istəyirsinizsə, CELT Secondary School sizin üçün ən doğru seçimdir. CELT Secondary School-də beynəlxalq standartlara uyğun təhsil proqramları ilə övladlarınız güclü bir təhsil təməli əldə edəcək."} />
              <meta name="keywords" content={"CELT Secondary School, beynəlxalq təhsil, orta məktəb təhsili, övladlar üçün təhsil, beynəlxalq məktəb, CELT təhsil proqramı, beynəlxalq təhsil proqramı, CELT orta məktəb"} />
              <meta property="og:url" content={`https://celt.az${window.location.pathname}`} />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
              <meta property="og:image:width" content="900" />
              <meta property="og:image:height" content="600" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={`Secondary School | CELT Colleges`} />
              <meta name="twitter:description" content={"Övladlarınızın ilk təhsilini beynəlxalq səviyyədə təmin etmək istəyirsinizsə, CELT Secondary School sizin üçün ən doğru seçimdir. CELT Secondary School-də beynəlxalq standartlara uyğun təhsil proqramları ilə övladlarınız güclü bir təhsil təməli əldə edəcək."} />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="7 minutes" />
            </Helmet>
            : null
        }



        {
          this.props.schoolType == 3 ?
            <title>Secondary School | CELT Colleges</title> : null
        }


        {
          this.props.schoolType == 1 ?
            <section className='container flex items-start gap-[32px] min-h-[500px] max-[850px]:flex-col max-[850px]:items-center  '>

              <div className='embed_header_video h-full  mt-[10px] w-1/2 max-[850px]:w-full' >
                <iframe
                  src="https://www.youtube.com/embed/COaJU62GF6M?si=bzZ52Hrfl-spAF-F"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen>
                </iframe>

              </div>

              <Card29
                title='High School'
                description={contentData?.high_school_description}
                text1={contentData?.high_school_text1}
                text2={contentData?.high_school_text2}

              />
            </section> : null
        }

        {
          this.props.schoolType == 2 ?
            <section className='container flex items-start gap-[32px] min-h-[500px] max-[850px]:flex-col max-[850px]:items-center  '>
              <div className='embed_header_video h-full  mt-[10px] w-1/2 max-[850px]:w-full' >
                <iframe
                  src="https://www.youtube.com/embed/Z8QBpwIyaJI?si=rgbrmbBmLGMTb_lK"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen>
                </iframe>
              </div>

              <Card29
                title='Primary School'
                description={contentData?.primary_school_description}
                text1={contentData?.primary_school_text1}
                text2={contentData?.primary_school_text2}

              />
            </section> : null
        }

        {
          this.props.schoolType == 3 ?
            <section className='container flex items-start gap-[32px] min-h-[500px] max-[850px]:flex-col max-[850px]:items-center  '>
              <div className='embed_header_video h-full  mt-[10px] w-1/2 max-[850px]:w-full' >
                <iframe
                  src="https://www.youtube.com/embed/cOAaxF6zJPU?si=Sw4Wjghcydb9dPXc"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  referrerpolicy="strict-origin-when-cross-origin"
                  allowfullscreen>
                </iframe>
              </div>
              <Card29 title='Secondary School'
                description={contentData?.school_secondary_description}
                text1={contentData?.school_secondary_text1}
                text2={contentData?.school_secondary_text2}
              />

            </section> : null
        }





        {
          kidsEnglishTypeData.length > 0 ?
            <section className='bg-[#f5f7f7] mt-[52px] pb-[52px]' >
              <div className='container'>
                <SectionHeader title="Diplomunu Seç" />
                <div className=' grid grid-cols-3 gap-[32px] max-[1080px]:grid-cols-2 max-[690px]:grid-cols-1 max-[420px]:gap-[50px]'>
                  {
                    kidsEnglishTypeData?.map((data, i) => {
                      return (
                        <Card19
                          key={i}
                          image={data?.image_full_url}
                          lvl_diploma={data?.translate?.title}
                          text={data?.translate?.text}
                        />
                      )
                    })
                  }
                </div>
              </div>
            </section> : null

        }


        {/* <section className='container' >
              <SectionHeader title={contentData?.nav_premium_graduent} />
              <div className='grid grid-cols-4 gap-[32px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[480px]:grid-cols-1 '>
                <Card21 />
                <Card21 />
                <Card21 />
                <Card21 />
              </div>
            </section> */}


        <section>
          {
            graduatsData.length > 0 ?
              <SectionHeader title={contentData?.nav_1} /> : null
          }
          <div className='container grid grid-cols-4 gap-[8px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>

            {
              graduatsData?.map((data, i) => {
                return (
                  <Card13
                    key={i}
                    first_name={data?.first_name}
                    last_name={data?.last_name}
                    company_name={data?.company_name}
                    image_full_url={data?.inst_image?.image_full_url}
                    country_image={data?.country_image}
                    country_name={data?.country_name}
                    user_image={data?.user_image}
                    program_name={data?.program_name}
                    inst_name={data?.inst_name}
                    ranking={data?.ranking}
                    grade={data?.grade}
                    company_id={data?.company_id}

                  />
                )
              })
            }
          </div>

          <div className=' flex justify-center  mt-[12px] ' >
            <Link to={`${langCode}/xaricde-oxuyanlar/`}>
              <button className='text-[var(--blue)] p-[6px_16px]  text-[16px] font-[600] border-[var(--blue)] rounded-[20px] border-[1px]'>
                {contentData?.btn_view_all}
              </button>
            </Link>
          </div>

          {/* {
                this.props.schoolType ==1?
                comp:null
              } */}
        </section>

        {/* <section className='container'>
              <SectionHeader title={contentData?.sh_schools} />
              
              <div>
                <SchoolsCompanies />
              </div>
          </section> */}

        {/* <div className='flex flex-col'> 
            <FaqSection faqs={faqs} page_type="school_page" />
          </div> */}

      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  graduatsData: state.Data.graduatsData,
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
  kidsEnglishTypeData: state.Data.kidsEnglishTypeData,

})
const mapDispatchToProps = { getGraduats, getKidsEnglishType }
export default connect(mapStateToProps, mapDispatchToProps)(SingleSchools)