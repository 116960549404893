import React from 'react';
import CloseSvg from './svg/CloseSvg';

class Modal extends React.Component {
  handleBackgroundClick = (e) => {
    if (e.target === e.currentTarget) {
      this.props.onClose();
    }
  }

  render() {
    const { show, onClose, imgSrc, onPrev, onNext } = this.props;

    if (!show) {
      return null;
    }

    return (
      <div style={styles.modalBackground} onClick={this.handleBackgroundClick}>
        <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
          <button style={styles.prevButton} onClick={onPrev}>&#10094;</button>
          <div style={styles.imageContainer}>
            <div className='flex justify-end'>
              <button style={styles.closeButton} onClick={onClose}> <CloseSvg /> </button>
            </div>

            <img src={imgSrc} alt="Enlarged" style={styles.modalImage} />
          </div>
          <button style={styles.nextButton} onClick={onNext}>&#10095;</button>
        </div>
      </div>
    );
  }
}

const styles = {
  modalBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0,0,0,0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContent: {
    position: 'relative',
    backgroundColor: 'none',
    padding: '0',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '90%',  // Oran olarak genişliği ve yüksekliği belirledik
    maxWidth: '900px',  // Maksimum genişlik
    height: '80%',  // Oran olarak yüksekliği belirledik
    maxHeight: '600px'  // Maksimum yükseklik
  },
  closeButton: {
    position: 'absolute',
    bottom: '-50px', // Adjust as needed for the desired spacing from the image
    right: '50%', // Center horizontally
    transform: 'translateX(50%)', // Center horizontally
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#333',
    cursor: 'pointer',
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '50%',
    width: '38px',
    height: '38px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    zIndex: '1001',
    
  },
  prevButton: {
    position: 'absolute',
    top: '50%',
    left: '-10px',
    transform: 'translateY(-50%)',
    fontSize: '26px',
    color: '#333',
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    zIndex: '1000'
  },
  nextButton: {
    position: 'absolute',
    top: '50%',
    right: '-10px',
    transform: 'translateY(-50%)',
    fontSize: '26px',
    color: '#333',
    backgroundColor: '#fff',
    border: 'none',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    zIndex: '1000'
  },
  imageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%'
  },
  modalImage: {
    maxWidth: '100%',
    maxHeight: '100%',
    width: 'auto',
    height: 'auto',
    margin: 'auto'
  },
  '@media screen and (max-width: 500px)': {
    prevButton: {
      width: '30px',
      height: '30px',
      fontSize: '20px'
    },
    nextButton: {
      width: '30px',
      height: '30px',
      fontSize: '20px'
    },
    closeButton: {
      top: '5px',
      right: '5px',
      fontSize: '16px'
    }
  }
};

export default Modal;