import React, { Component } from 'react'

export class GraduationSvg extends Component {
  render() {
    return (
      //   <svg
      //   xmlns="http://www.w3.org/2000/svg"
      //   xmlSpace="preserve"
      //   width={24}
      //   height={24}
      //   viewBox="0 0 42.672 42.672"
      //   fill='var(--blue)'
        
      // >
      //   <path d="M38.729 25.815v-4.09l3.943-2.179L21.337 8.151 0 19.547l8.506 4.94v3.138c.023-.012.052-.023.077-.035-.091.125-.15.247-.15.375 0 1.428 5.774 2.592 12.904 2.592 7.126 0 12.906-1.164 12.906-2.592 0-.128-.062-.25-.15-.375.025.015.054.023.076.035v-3.146l3.711-2.283v3.619a1.117 1.117 0 1 0 .849 0zm-17.392 3.759c-6.327 0-11.453-.722-11.453-1.608s5.126-1.606 11.453-1.606c6.324 0 11.452.72 11.452 1.606s-5.128 1.608-11.452 1.608zm12.553-7.471c-.85-.909-3.908-3.295-12.553-3.295-8.65 0-11.703 2.388-12.552 3.298v-.864s2.476-3.717 12.552-3.717c10.075 0 12.553 3.717 12.553 3.717v.861z" />
      //   <path d="m37.4 28.194-.468 6.328c1.36-2.109 2.481-1.314 2.481-1.314v-5.014c-1.45.563-2.013 0-2.013 0z" />
      // </svg>

      <svg
    xmlns="http://www.w3.org/2000/svg"
    width={30}
    height={30}
    className="cf-icon-svg"
    viewBox="-1 0 19 19"
    fill='var(--blue)'
   
  >
    <path d="M16.417 9.583A7.917 7.917 0 1 1 8.5 1.666a7.917 7.917 0 0 1 7.917 7.917zM3.024 8.034s-.15.06-.15.155.15.155.15.155l5.117 2.171a1.074 1.074 0 0 0 .73 0l3.1-1.315 2.015-.855a.464.464 0 0 0 .05-.025l.006-.004.016-.01a.148.148 0 0 0 .078-.116c0-.057-.05-.112-.15-.155L8.87 5.863a.922.922 0 0 0-.312-.063h-.053a.969.969 0 0 0-.364.063L3.024 8.034zm8.948 1.853-2.854 1.211a1.699 1.699 0 0 1-1.225 0L5.04 9.887v1.565c0 1.009 1.552 1.826 3.466 1.826s3.467-.817 3.467-1.826zm1.882 1.992a.595.595 0 0 0-1.19 0v.79h1.19zm-.595-1.959a.396.396 0 1 0-.396-.395.396.396 0 0 0 .396.395zm-.396.672a.396.396 0 1 0 .396-.396.396.396 0 0 0-.396.396z" />
  </svg>
    )
  }
}

export default GraduationSvg