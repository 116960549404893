import React, { Component } from 'react'

export class SectionDescription extends Component {
  render() {
    const {text} = this.props
    return (
      <div className='container w-full flex justify-center'>
        <p className='  text-center max-w-[900px] font-[400] text-[17px] text-[#828c8a] '>{text}</p>
      </div>
    )
  }
}

export default SectionDescription
