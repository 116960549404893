import React, { Component } from 'react'

export class Card17 extends Component {
  render() {
    const {logo_full_url} = this.props;
    return (
      <div className='p-[0px_15px]'>
        <img src={logo_full_url} className='w-auto h-[100px]' />
      </div>
    )
  }
}

export default Card17