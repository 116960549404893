import React, { Component } from 'react'
import { connect } from 'react-redux';

export class Card22 extends Component {
  render() {
    const{border, img, size, text, border_bottom, svg, width, height, contentData} = this.props;
    return (
      <div className={`${border} ${border_bottom}  border-[#fff]  flex flex-col justify-between items-center gap-[16px] p-[14px] `}>
            
            <div>
              <img className={`${width} ${height}`} src={img} />
            </div>

            <div>
              {svg}
            </div>
            <span className='text-[35px] font-[700] text-center text-[#fff] max-[850px]:text-[24px] max-[850px]:font-[600]'>{size} {contentData?.clock}</span>

            <span className='text-[17px] font-[600] text-center leading-[20px] h-[80px] text-[#fff] max-[850px]:text-[15px] max-[850px]:font-[500]'>{text}</span>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,

})

export default connect(mapStateToProps)( Card22)