import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getprimaryCompanies } from '../actions/MainAction';
import Card8 from './cards/Card8';
import { Helmet } from 'react-helmet';

export class PrimaryCompanies extends Component {
  componentDidMount() {
    this.props.getprimaryCompanies(this.props.lang_id);
  }

  // Fonksiyon, bir dizi içindeki öğeleri rastgele sıralamak için kullanılabilir
  shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  render() {
    const { primaryCompaniesData } = this.props;
    // console.log(primaryCompaniesData)
    // Verileri rastgele sırala
    const shuffledCompanies = this.shuffleArray(primaryCompaniesData);
    
    return (
      <div>
        <Helmet>
          <title>CELT Colleges Filialları - Tədris Mərkəzlərimizin Ünvanları - CELT Colleges</title>
          <meta name="description" content="CELT Colleges filialları sizə yaxın hər yerdədir. Sumqayıtda 1, Bakıda isə 12 mərkəzimizlə, keyfiyyətli təhsil üçün sizinləyik." />
          <meta property="og:locale" content="az_AZ" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="CELT Colleges Filialları - Tədris Mərkəzlərimizin Ünvanları" />
          <meta property="og:description" content="CELT Colleges filialları sizə yaxın hər yerdədir. Sumqayıtda 1, Bakıda isə 12 mərkəzimizlə, keyfiyyətli təhsil üçün sizinləyik." />
          <meta property="og:url" content="https://celt.az/contactus/" />
          <meta property="og:site_name" content="CELT Colleges" />
          <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
          <meta property="article:modified_time" content="2023-11-27T14:28:10+00:00" />
          <meta property="og:image" content="https://celt.az/static/media/celtLogo.a95db1d2d66058755538.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@celtcolleges" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="3 minutes" />
          <link rel="icon" href="/celt-title.jpeg" />
        </Helmet>
        
        <div className='grid grid-cols-3 gap-[32px] mt-[52px]  max-[1000px]:grid-cols-2 max-[600px]:grid-cols-1'>
        {
          shuffledCompanies?.map((data, i) => (
            <Card8
              key={i}
              title={data?.title}
              phone_number={data?.phone_number}
              location={data?.location}
              facebook={data?.facebook}
              instagram={data?.instagram}
              office_number={data?.office_number}
              id={data?.id}
              path={`${data?.path}`}
              image_full_url={data?.image_full_url}
            />
          ))
        }
        </div>
      </div>
      
    );
  }
}

const mapStateToProps = (state) => ({
  lang_id: state.Data.lang_id,
  primaryCompaniesData: state.Data.primaryCompaniesData,
});

const mapDispatchToProps = { getprimaryCompanies };

export default connect(mapStateToProps, mapDispatchToProps)(PrimaryCompanies);