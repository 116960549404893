import React, { Component } from 'react'

export class Loadin extends Component {
  render() {
    return (
      <div className='fixed top-0 left-0 w-full h-screen flex justify-center items-center bg-[rgba(0,0,0,0.5)] z-[999]'>
         <div className="loader"></div>
      </div>
    )
  }
}

export default Loadin