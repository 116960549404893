import React, { Component } from 'react';
import { getCountries } from '../actions/MainAction';
import { connect } from 'react-redux';
import Card16 from './cards/Card16';
import { getPathUrl } from '../actions/company_path';

export class CountriesSection extends Component {
    componentDidMount() {
        this.props.getCountries(this.props.lang_id);
    }

    render() {
        const { countriesData } = this.props;
        // console.log(countriesData)
        // Verileri university_count'a göre sırala
        const sortedCountriesData = [...countriesData].sort((a, b) => (b?.countries?.institution_count || 0) - (a?.countries?.institution_count || 0));

        return (
            <div className='grid grid-cols-5 gap-[32px] max-[1220px]:grid-cols-4 max-[980px]:grid-cols-3 max-[740px]:grid-cols-2 max-[500px]:grid-cols-1'>
                {sortedCountriesData?.map((data, i) => (
                    <Card16
                        key={i}
                        image_full_url={data?.countries?.image_full_url}
                        institution_count={data?.countries?.institution_count}
                        name={data?.name}
                        path={`/xaricde-tehsil/${getPathUrl(data?.name)}`}
                    />
                ))}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    countriesData: state.Data.countriesData,
    lang_id: state.Data.lang_id
});

const mapDispatchToProps = { getCountries };

export default connect(mapStateToProps, mapDispatchToProps)(CountriesSection);