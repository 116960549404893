import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getkidsCompanies } from '../actions/MainAction';
import Card8 from './cards/Card8';

export class KidsCompanies extends Component {
  componentDidMount() {
    this.props.getkidsCompanies(this.props.lang_id);
  }

  // Fonksiyon, bir dizi içindeki öğeleri rastgele sıralamak için kullanılabilir
  shuffleArray = (array) => {
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  render() {
    const { kidsCompaniesData } = this.props;
    // console.log(kidsCompaniesData)
    // Verileri rastgele sırala
    const shuffledCompanies = this.shuffleArray(kidsCompaniesData);
    
    return (
      <div className='grid grid-cols-3 gap-[32px] mt-[52px]  max-[1000px]:grid-cols-2 max-[600px]:grid-cols-1'>
        {
          kidsCompaniesData?.map((data, i) => (
            <Card8
              key={i}
              title={data?.title}
              phone_number={data?.phone_number}
              location={data?.location}
              facebook={data?.facebook}
              instagram={data?.instagram}
              office_number={data?.office_number}
              id={data?.id}
              path={`${data?.path}`}
              image_full_url={data?.image_full_url}

            />
          ))
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang_id: state.Data.lang_id,
  kidsCompaniesData: state.Data.kidsCompaniesData,
});

const mapDispatchToProps = { getkidsCompanies };

export default connect(mapStateToProps, mapDispatchToProps)(KidsCompanies);