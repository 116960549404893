import React, { Component } from 'react'
import FaqSection from '../components/FaqSection'
import { getEnglishExamResults, getExamResults } from '../actions/MainAction'
import { connect } from 'react-redux'
import Card2 from './cards/Card2'
import LoadMoreBtn from './LoadMoreBtn'
import StudentReviews from './StudentReviews'
import SectionHeader from './SectionHeader'
import { withHooks } from '../actions/withHooks'
import Card27 from './cards/Card27'


export class SliderSection extends Component {
  state={
    slidePage:0,
    results:[],
    pageid:"",
    
  }


componentDidUpdate(prevProps){

  if(prevProps.exam_type_id != this.props.exam_type_id){
      this.props.getExamResults(0.30,"filter_by_exam",this.props.exam_type_id ,'')
      .then(resp=>{
          this.setState({results: resp})
      })
  }
}
loadMore=(page)=>{
  this.props.getExamResults(0.30*page,"filter_by_exam", this.props.exam_type_id,'')
      .then(resp=>{
          this.setState({results: resp})
      })
}

loadMoreExamResults=(page)=>{
  this.props.getEnglishExamResults(0.30*page)
}

  render() {
    const {slidePage, results} = this.state;
    const {overView, faqs=[], exam_type_id="", primaryCompaniesData, contentData, englishExamResults} = this.props;
    // console.log(englishExamResults)
    // console.log(results)
    return (
       
      <div className='w-full mt-[15px]'>
        <div className='w-full flex flex-col gap-[52px]'>
          {
            results.length > 1 ?

            <div className='flex flex-col'>
            {
              results.length > 0 ? 
              <SectionHeader title={contentData?.sh_our_results} /> : null
            }
            <div>
              <div className='grid grid-cols-3 gap-[20px] max-[800px]:grid-cols-2 max-[530px]:grid-cols-1'>
                {
                  results?.map((data,i)=>{
                    return(
                      <Card2 
                      key={i}
                      image_full_url={data?.image_full_url}
                      title={data?.exam?.title}
                      student_name={data?.student_name}
                      date={data?.date}
                      company={data?.company?.title}
                      company_id={data?.company_id}
                      score={data?.score}
                      url={data?.cert_image_full_url}
                  />
                    )
                  })
                }
              </div>
                {
                    results?.length >=5?
                    <LoadMoreBtn onLoadData={this.loadMore} defCount={6} count={results?.length} />:null
                }
            </div>
          </div>:null
          }
          
          {
            englishExamResults.length >1 ?
            <div>
            {
              englishExamResults.length > 0 ? 
              <SectionHeader title={contentData?.sh_our_results} /> : null
            }
            <div className='grid grid-cols-3 gap-[20px] max-[800px]:grid-cols-2 max-[530px]:grid-cols-1'>
                
              {
                englishExamResults?.map((data,i)=>{
                  return(
                    <Card27
                      key={i}
                      first_name={data?.student?.first_name}
                      full_name={data?.student?.full_name}
                      last_name={data?.student?.last_name}
                      title={data?.exam?.lesson?.title}
                      score={data?.score}
                      company={data?.student?.company?.name}
                      company_id={data?.student?.company_id}
                      date={data?.created_at}
                      image_full_url={data?.student?.avatar_full_url}
                      url={data?.certificate?.certificate_full_url}
                      
                    />
                  )
                })
              }
            </div>
              {
                englishExamResults?.length >=5?
                <LoadMoreBtn onLoadData={this.loadMoreExamResults} defCount={6} count={englishExamResults?.length} />:null
              }
          </div>:null
          }
          

          <div className='flex flex-col'>
            <SectionHeader title={contentData?.review} />                  
            <StudentReviews dataCount={4} grid_cols='grid-cols-2' />
          </div>

          <div className='flex flex-col'>
            {
              overView !== '' ?
              <SectionHeader title={contentData?.sh_lesson_plan} /> : null
            }
            
            
            <div className='container w-full list-design innertext  ' dangerouslySetInnerHTML={{__html:overView}}></div>
          </div>
         
          
          {/* <div className='flex flex-col'>
            <FaqSection faqs={faqs} />
          </div> */}
          
            
          </div>
      </div>  
        
    )
  }
}
const mapStateToProps = (state) =>({
  contentData: state.Data.contentData,
  englishExamResults: state.Data.englishExamResults,

})
const mapDispatchToProps = {getExamResults, getEnglishExamResults}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(SliderSection))



