import React, { Component } from 'react'
import { withHooks } from '../actions/withHooks';
import { getEnglishExamResults, getSingleData } from '../actions/MainAction';
import { connect } from 'react-redux';
import SectionHeader from '../components/SectionHeader';
import SliderSection from '../components/SliderSection'
import VideoSection from '../components/VideoSection';
import { changeStateValue } from '../redux/MainReducer';
import { Helmet } from 'react-helmet';
import { getTextFromHTML } from '../actions/others';
export class SingleExams extends Component {
  state = {
    examData: '',
  }
  onlickSlide(index, e) {
    this.setState({ play: index })
  }
  // componentDidMount(){
  //   const {params, lang_id, getSingleData, getEnglishExamResults} = this.props;
  //   // console.log(params)
  //     getSingleData(`exams/${params.id}?lang_id=${lang_id}`)
  //     .then(resp=>{
  //       // console.log(resp)
  //         this.setState({examData:resp})
  //     })
  //     window.scrollTo(0,0)

  //     if(params.id == 11 || params.id == 13 || params.id == 14 ){
  //       getEnglishExamResults(0.30)
  //     }else{
  //       this.props.changeStateValue({
  //         name:"englishExamResults",
  //         value:[]
  //       })
  //     }
  // }

  componentDidMount() {
    const { params, lang_id, getSingleData, getEnglishExamResults, changeStateValue } = this.props;

    // localStorage'dan dil bilgisi al
    const savedLanguage = localStorage.getItem('language');
    const langId = savedLanguage ? savedLanguage : lang_id; // Eğer kaydedilmiş bir dil varsa onu kullan, yoksa varsayılan dil

    // getSingleData ile sayfaya özel veri al ve state'e ata
    getSingleData(`exams/${params.id}?lang_id=${langId}`)
      .then(resp => {
        this.setState({ examData: resp });
      });

    // Sayfayı en üste kaydır
    window.scrollTo(0, 0);

    // params.id'ye göre ek işlemler yap
    if (params.id === 11 || params.id === 13 || params.id === 14) {
      getEnglishExamResults(0.30);
    } else {
      changeStateValue({
        name: "englishExamResults",
        value: []
      });
    }
  }

  render() {

    const { examData, play, sectionfixed } = this.state;
    const { englishExamResults } = this.props;
    // console.log(examData)
    // console.log(examData);
    // console.log(englishExamResults)
    // console.log(window.location.pathname)
    /*
        
    */
    return (
      <div className='container '>
        {
          examData != "" ?
            <Helmet>
              <title>{examData?.translate?.title} | CELT Colleges</title>
              <meta property="og:locale" content="az_AZ" />
              <meta property="og:type" content="website" />
              <meta property="og:title" content={`${examData?.translate?.title} | CELT Colleges`} />
              <meta property="og:description" content={examData?.translate?.seo_description} />
              <meta name="description" content={examData?.translate?.seo_description} />
              <meta name="keywords" content={examData?.translate?.seo_keywords} />
              <meta property="og:url" content={`https://celt.az${window.location.pathname}`} />
              <meta property="og:site_name" content="CELT Colleges" />
              <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
              <meta property="article:modified_time" content="2024-06-06T08:56:25+00:00" />
              <meta property="og:image" content={examData?.image_full_url} />
              <meta property="og:image:width" content="900" />
              <meta property="og:image:height" content="600" />
              <meta property="og:image:type" content="image/jpeg" />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:title" content={`${examData?.translate?.title} | CELT Colleges`} />
              <meta name="twitter:description" content={examData?.translate?.seo_description} />
              <meta name="twitter:site" content="@celtcolleges" />
              <meta name="twitter:label1" content="Est. reading time" />
              <meta name="twitter:data1" content="7 minutes" />
            </Helmet> : null
        }

        <section className='flex single_exams gap-[60px] sticky top-0 items-start   max-[1080px]:flex-col-reverse max-[1080px]:items-center '>

          <div className='  h-auto w-full gap-[32px]  max-w-[760px]'>
            {/* <img className=' w-full rounded-[6px]' src={examData?.image_full_url} /> */}
            <SectionHeader title={examData?.translate?.title} />
            <div className='flex flex-col gap-[12px] w-full singleexamheader' dangerouslySetInnerHTML={{ __html: examData?.translate?.text }}>
            </div>

            <SliderSection exam_type_id={examData?.exam_type_id} faqs={examData?.faqs} overView={examData?.translate?.description} />
          </div>

          <VideoSection examData={examData} />
        </section>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
  englishExamResults: state.Data.englishExamResults,

});
const mapDispatchToProps = { getSingleData, getEnglishExamResults, changeStateValue }
export default connect(mapStateToProps, mapDispatchToProps)(SingleExams)