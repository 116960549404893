import React, { Component } from 'react'
import Card2 from './cards/Card2'
import SectionHeader from './SectionHeader'
import { getExamResults } from '../actions/MainAction';
import { connect } from 'react-redux';
import LoadMoreBtn from './LoadMoreBtn';

export class ExamResultsSection extends Component {
    state = {
        results: []
    }
    componentDidMount() {
        this.props.getExamResults(this.props.page, this.props.type, this.props.exam_type_id, this.props.companyId)
            .then(resp => {
                this.setState({ results: resp })
            })
    }
    loadMore = (page) => {
        this.props.getExamResults(this.props.page * page, this.props.type, this.props.exam_type_id, this.props.companyId)
            .then(resp => {
                this.setState({ results: resp })
            })
    }
    render() {
        const { results } = this.state;
        const { mt = 'mt-[40px]' } = this.props
        // console.log(results)
        return (
            <div className=''>
                {
                    results.length > 1 ?
                        <section className={mt}>
                            <div className='pt-[52px]'>
                                <SectionHeader title={results[0]?.exam?.title} />
                            </div>
                            <div className='grid grid-cols-4 gap-[20px]  max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>
                                {
                                    results?.map((data, i) => {
                                        return (
                                            <Card2
                                                key={i}
                                                image_full_url={data?.image_full_url}
                                                title={data?.exam?.title}
                                                student_name={data?.student_name}
                                                date={data?.date}
                                                company={data?.company?.title}
                                                company_id={data?.company_id}
                                                score={data?.score}
                                                url={data?.cert_image_full_url}
                                            />
                                        )
                                    })
                                }

                            </div>
                            {
                                results?.length >= 8 ?
                                    <LoadMoreBtn onLoadData={this.loadMore} defCount={8} count={results?.length} /> : null
                            }
                        </section> : null
                }
            </div>

        )
    }
}
const mapStateToProps = (state) => ({});
const mapDispatchToProps = { getExamResults }
export default connect(mapStateToProps, mapDispatchToProps)(ExamResultsSection)