import React, { Component } from 'react'
import building from '../../images/building.png'
import { Link } from 'react-router-dom'

export class BlogCard extends Component {
  render() {
    const {flex, width, border,  justify_between, path,
        title,date, image=""} = this.props;
        // console.log(path)
    return (
        <Link to={`/blog/${path}`} target='__blank'>
        <div className={`${flex} ${border} ${justify_between}  items-center gap-[20px] pb-[20px] `}>
            <img className={` ${width}  rounded-[8px] `} src={image}  />

            <div className='flex flex-col gap-[4px]'>
                <h4 className='text-[20px] font-[400] mt-[24px] text-[#000] max-[400px]:text-[16px]'>
                    {title}
                </h4>

                <span className='text-[14px] font-[400] text-[var(--medium-gray)] mt-[8px]'>
                    {date}
                </span>
            </div>
            
        </div>
    </Link>
    )
  }
}

export default BlogCard
