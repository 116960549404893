import React, { Component } from 'react'

export class NextArrowSvg extends Component {
  render() {
    const {width, height, fill} = this.props;
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={width}
    height={height}
    viewBox="0 0 512.005 512.005"
   fill={fill}
  >
    <path d="M388.418 240.923 153.751 6.256c-8.341-8.341-21.824-8.341-30.165 0s-8.341 21.824 0 30.165L343.17 256.005 123.586 475.589c-8.341 8.341-8.341 21.824 0 30.165a21.275 21.275 0 0 0 15.083 6.251 21.277 21.277 0 0 0 15.083-6.251l234.667-234.667c8.34-8.34 8.34-21.823-.001-30.164z" />
  </svg>
    )
  }
}

export default NextArrowSvg