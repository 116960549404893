import React, { Component } from 'react'

export class UserSvg1 extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={19}
        fill="none"
        
      >
        <path
          fill="#264EA9"
          d="M7.898 18.452c-1.13 0-2.26.006-3.39-.002a33.914 33.914 0 0 1-1.698-.059c-.589-.034-1.176-.094-1.746-.26-.276-.08-.542-.184-.764-.376a.807.807 0 0 1-.3-.63c0-.308-.005-.616.021-.922a5.919 5.919 0 0 1 1.391-3.313 6.051 6.051 0 0 1 1.678-1.366c.46-.254.944-.452 1.455-.572.32-.075.648-.14.976-.157.607-.03 1.216-.026 1.824-.027.964-.003 1.927-.008 2.89.005.53.007 1.046.114 1.552.27.75.234 1.43.602 2.036 1.1a5.947 5.947 0 0 1 1.707 2.268c.223.518.373 1.058.43 1.617.037.367.043.738.04 1.107-.004.377-.241.606-.553.773-.309.165-.643.252-.983.317-.52.099-1.045.142-1.573.172-1.469.083-2.94.047-4.41.055h-.583ZM12.488 4.573c-.01 1.124-.35 2.08-1.026 2.915A4.609 4.609 0 0 1 8.95 9.084c-.48.122-.97.162-1.468.127a4.676 4.676 0 0 1-2.46-.892 4.568 4.568 0 0 1-1.485-1.811 4.55 4.55 0 0 1-.31-2.871A4.604 4.604 0 0 1 5.597.544c.449-.237.924-.4 1.425-.482.368-.06.738-.076 1.113-.05a4.604 4.604 0 0 1 1.913.55 4.596 4.596 0 0 1 2.123 2.381c.162.4.264.817.294 1.248.01.144.017.288.023.382Z"
        />
      </svg>
    )
  }
}

export default UserSvg1