export const initialState = {
    lang_id:1,
    loader: false,
    loaderLong: false,
    phoneValidFormat:false,
    
    mainInfoData:[],
    faqData:[],
    langPrepationData:[],
    studyAbroadData:[],
    kidsEnglishTypeData:[],
    whyUsData:[],
    studentsReviewsData:[],
    contentData:{},
    examsData:[],
    primaryCompaniesData:[],
    kidsCompaniesData:[],
    schoolsCompaniesData:[],
    companyData:'',
    acceptedStudentsData:[],
    graduatsData:[],
    countriesData:[],
    universityLogoData:[],
    blogsData:[],
    statisticsData:[],
    galleryModalData:{
        position:false,
        image:''
    },
    popupData:[],
    publicUniversities:[],
    publicPrograms:[],
    degreeByUniversity:[],
    blogComments:[],
    adminPremiumStudents:[],
    englishExamResults:[],
    popUpVisible:false
}
