import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withHooks } from '../actions/withHooks';
import { getSingleData } from '../actions/MainAction';
import HourSvg from './svg/HourSvg';
import Language2Svg from './svg/Language2Svg';
import LessonSvg from './svg/LessonSvg';
import CertificateSvg from './svg/CertificateSvg';
import Instagram from './svg/Instagram';
import FacebookSvg from './svg/FacebookSvg';
import LinkedinSvg from './svg/LinkedinSvg';
import PeopleSvg from './svg/PeopleSvg';
import LikeSvg from './svg/LikeSvg';
import PopupComponent from './PopupComponent';

class VideoSection extends Component {
  state = {
    play: 0,
    sectionfixed: 0,
    examData: '',
    showPopup: false,
    inputValue: '',
    surnameValue: '',
    errorInput: false,
    errorSurname: false,
  };

  handleChangeInput = (event) => {
    this.setState({
      inputValue: event.target.value,
      errorInput: false,
    });
  };

  handleChangeSurname = (event) => {
    this.setState({
      surnameValue: event.target.value,
      errorSurname: false,
    });
  };

  handleBlurInput = () => {
    if (!this.state.inputValue) {
      this.setState({ errorInput: true });
    }
  };

  handleBlurSurname = () => {
    if (!this.state.surnameValue) {
      this.setState({ errorSurname: true });
    }
  };

  onClickSlide = (index, e) => {
    this.setState({ play: index });
  }

  componentDidMount() {
    // this.props.getSingleData(`exams/${this.props.params.id}?lang_id=${this.props.lang_id}`).then((resp) => {
    //   this.setState({ examData: resp });
    // });
    // window.scrollTo(0, 0);
  }

  togglePopup = () => {
    this.setState((prevState) => ({ showPopup: !prevState.showPopup }));
  };

  render() {
    const { play,  showPopup, inputValue, surnameValue, errorInput, errorSurname } = this.state;
    const { contentData, examData } = this.props;
    // console.log(examData);
    return (
      <div className="rounded-[8px] flex flex-col w-[350px] mt-[52px] bg-[#f1f6f6] max-[690px]:w-full z-10">
        
        <div className='embed_video' dangerouslySetInnerHTML={{__html: examData.video_full_url }}></div>

        <div className="px-[35px] py-[32px] flex flex-col gap-[19px]">
          {/* Other content */}
          <div className="flex items-center justify-between border-b-[1px] pb-[10px]">
            <div className="flex items-center gap-[6px]">
              <HourSvg />
              <span className="font-[600] text-[17px] leading-[30px] text-[var(--dark-gray)]">
                {contentData?.video_sc_duration}
              </span>
            </div>
            <span className="text-[17px] leading-[30px] font-[400] text-[var(--medium-gray)]">{examData?.duration} {contentData?.video_sc_week}</span>
          </div>
          {/* <div className="flex items-center justify-between border-b-[1px] pb-[10px]">
            <div className="flex items-center gap-[10px]">
              <PeopleSvg />
              <span className="font-[600] text-[17px] leading-[30px] text-[var(--dark-gray)]">{contentData?.video_sc_enrolled}</span>
            </div>
            <span className="text-[17px] leading-[30px] font-[400] text-[var(--medium-gray)]">
              {examData?.student_count} {contentData?.student}
            </span>
          </div> */}
          <div className="flex items-center justify-between border-b-[1px] pb-[10px]">
            <div className="flex items-center gap-[10px]">
              <Language2Svg fill="var(--gray)" width="26" height="26" />
              <span className="font-[600] text-[17px] leading-[30px] text-[var(--dark-gray)]">{contentData?.language}</span>
            </div>
            <span className="text-[17px] leading-[30px] font-[400] text-[var(--medium-gray)]">{contentData?.english}</span>
          </div>
          <div className="flex items-center justify-between border-b-[1px] pb-[10px]">
            <div className="flex items-center gap-[10px]">
              <LessonSvg />
              <span className="font-[600] text-[17px] leading-[30px] text-[var(--dark-gray)]">{contentData?.card_lessons}</span>
            </div>
            <span className="text-[17px] leading-[30px] font-[400] text-[var(--medium-gray)]">{examData?.lessons} {contentData?.video_sc_lesson_number} </span>
          </div>
          <div className="flex items-center justify-between border-b-[1px] pb-[10px]">
            <div className="flex items-center gap-[10px]">
              <CertificateSvg />
              <span className="font-[600] text-[17px] leading-[30px] text-[var(--dark-gray)]">{contentData?.video_sc_certificate}</span>
            </div>
            <span className="text-[17px] leading-[30px] font-[400] text-[var(--medium-gray)]">{contentData?.video_sc_yes}</span>
          </div>
          <div className="flex flex-col gap-[16px] items-center mt-[18px]">
            <button
              onClick={this.togglePopup}
              className="font-[600] text-[17px] text-[#313e3b] bg-[#d5d52b] cursor-pointer py-[14px] rounded-[50px] border-[1px] border-[#d5d52b] w-full flex items-center justify-center gap-[8px]"
            >
              <LikeSvg width="24" height="24" fill="#000" />
              {contentData?.video_sc_enroll}
            </button>
          </div>
          <div className="mt-[10px] flex items-center justify-center gap-[10px]">
            <Instagram width="30px" height="30px" fill="var(--dark-gray)" />
            <FacebookSvg width="25px" height="25px" fill="var(--dark-gray)" />
            <LinkedinSvg width="19px" height="19px" fill="var(--dark-gray)" />
          </div>
        </div>

        <PopupComponent show={showPopup} onClose={this.togglePopup}>
          {/* Popup content */}
        </PopupComponent>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
});

const mapDispatchToProps = { getSingleData };

export default connect(mapStateToProps, mapDispatchToProps)(withHooks(VideoSection));


