import React, { Component } from 'react'
import image from '../../images/building.png'
import { connect } from 'react-redux';

export class Card6 extends Component {
  render() {
    const{title, type, text, contentData }=this.props;
    return (
      <div className='shadow  bg-[var(--white)] rounded-[8px] pb-[8px]  container'>
        <img className='rounded-[8px_8px_0_0] w-full h-[224px]' src={image} />
        <div className='p-[5px_10px]'>
          <div>
            <div>
                <h3 className=' text-[30px] font-[600] text-center text-[var(--dark-blue)]'>{title}</h3>
                <p className='text-[17px] font-[500]  text-center text-gray-600'>{type}</p>
            </div>
          </div>
          <div className='flex flex-col gap-[25px] text-center text-gray-600' dangerouslySetInnerHTML={{__html:text}}></div>
          <div className='flex justify-center ml-[20px] mr-[20px]'>
            <button className=' mt-[22px]  p-[4px_16px] w-full bg-[none] text-[var(--blue)] font-[500] text-[16px] rounded-[16px] border border-solid border-[var(--blue)]'>{contentData?.celt_certificate}</button>
          </div>
        </div>
       
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData, 
  lang_id: state.Data.lang_id
})
export default connect(mapStateToProps)( Card6)