import React, { Component } from 'react'

export class SectionHeader extends Component {
  render() {
    const {title}  = this.props
    return (
      <div  className={`container pb-[32px] pt-[52px] gap-[20px] justify-center flex items-center max-[685px]:flex-col  max-[685px]:gap-[10px]`}>
        <span className='h-[15px] border-t border-b border-t-[var(--blue)] border-b-[var(--blue)] inline-block w-[150px] max-[685px]:h-[0px] max-[685px]:w-[40%] max-[685px]:border-b-[0px]'></span>
        <h2 className='text-[40px] text-center font-[600] text-[#313e3b]  max-[350px]:text-[30px] max-[800px]:text-[28px]  '>
        {title} 
        </h2>
        <span className='h-[15px] border-t border-b border-t-[var(--blue)] border-b-[var(--blue)] inline-block w-[150px] max-[685px]:h-[0px] max-[685px]:w-[40%] max-[685px]:border-b-[0px]'></span>
      </div>
    )
  }
}

export default SectionHeader