import React, { Component } from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { connect } from 'react-redux';
import { getUniversityLogo } from '../actions/MainAction';

import Card17 from '../components/cards/Card17'

export class LogoSlider extends Component {
   componentDidMount(){
    this.props.getUniversityLogo( this.props.params_id);
   }
    
  render() {
    const settings = {
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      };

      const {universityLogoData} = this.props;
      // console.log(universityLogoData);
    return (
      <div className='mt-[20px]' >
        <Slider {...settings}>
            {
              universityLogoData?.map((data, i)=>{
                return(
                  <Card17
                  key={i}
                  logo_full_url = {data?.logo_full_url}
                  />
                )
              })
            }
        </Slider>
      </div>
    )
  }
}

const mapStateToProps = (state) =>({
  universityLogoData: state.Data.universityLogoData,
  lang_id: state.Data.lang_id,
})

const mapDispatchToProps = {getUniversityLogo}
export default connect(mapStateToProps, mapDispatchToProps ) (LogoSlider)
