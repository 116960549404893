import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import Card1 from '../components/cards/Card1'
import { getEnglishExamResults, getLangPrepationData } from '../actions/MainAction'
import { getstudentsReviewsData } from '../actions/MainAction'
import { connect } from 'react-redux'
import StudentReviews from '../components/StudentReviews'
import FaqSection from '../components/FaqSection'
import Card27 from '../components/cards/Card27'
import LoadMoreBtn from '../components/LoadMoreBtn'
import { Helmet } from 'react-helmet'

const examPaths = {
  11: '/ingilis-dili-kurslari',
  13: '/online-ingilis-dili',
  14: '/crash-ingilis-dili',


};


export class LanguagePreparation extends Component {
  componentDidMount() {

    const savedLanguage = localStorage.getItem('language');

    // Eğer kaydedilmiş bir dil varsa, onu kullan
    if (savedLanguage) {
      this.props.getLangPrepationData(savedLanguage); // savedLanguage'yi lang_id olarak kullan
    } else {
      this.props.getLangPrepationData(this.props.lang_id)
      // varsayılan lang_id
    }


    const { getLangPrepationData, getstudentsReviewsData, getEnglishExamResults } = this.props;

    getEnglishExamResults(0.4, "");
    window.scrollTo(0, 0)
  }

  loadMoreExamResults = (page) => {
    const { getEnglishExamResults } = this.props;
    getEnglishExamResults(0.4 * page, "")
  }
  render() {
    const { langPrepationData, contentData, faqs = [], englishExamResults } = this.props;
    // console.log(langPrepationData)
    return (
      <div>
        <Helmet>
          <title>{contentData?.laguage_preparation_title}</title>
          <meta name="description" content="CELT Colleges ingilis dili kursu ilə, dil öyrənməyin necə asan olduğunu görəcəksiniz. Linkə daxil olun və ətraflı məlumat alın!" />

          <meta property="og:locale" content="az_AZ" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="Ingilis Dili Kursu - Bakıda Ən Yaxşı İngilis Dili Kursu" />
          <meta property="og:description" content="CELT Colleges ingilis dili kursu ilə, dil öyrənməyin necə asan olduğunu görəcəksiniz. Linkə daxil olun və ətraflı məlumat alın!" />
          <meta property="description" content="CELT Colleges ingilis dili kursu ilə, dil öyrənməyin necə asan olduğunu görəcəksiniz. Linkə daxil olun və ətraflı məlumat alın!" />
          <meta property="keywords" content="CELT Colleges, ingilis dili kursu, dil öyrənmə, asan ingilis dili öyrənmə, CELT Colleges kursları, dil kursları, ingilis dili təhsili" />
          <meta property="og:url" content="https://celt.az/ingilis-dili-kurslari/" />
          <meta property="og:site_name" content="CELT Colleges" />
          <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
          <meta property="article:modified_time" content="2022-08-09T13:53:32+00:00" />
          <meta property="og:image" content="https://celt.az/static/media/celtLogo.a95db1d2d66058755538.png " />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@celtcolleges" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="3 minutes" />
        </Helmet>
        <SectionHeader pt="pt-[52px]" title={contentData?.english_cource} />

        <section className='  container grid grid-cols-3 gap-[32px] justify-center pt-[52px]  max-[1100px]:gap-[26px] max-[900px]:grid-cols-2 max-[600px]:grid-cols-1'>

          {
            langPrepationData?.map((data, i) => {
              const path = examPaths[data.id] || `/exams/${data.id}`;
              return (
                <Card1
                  price='50'
                  cardsinfo='1'
                  key={i}
                  image={data?.image_full_url}
                  path={path}
                  title={data?.translate?.title}
                  text={data?.translate?.text}
                  lessons={data?.lessons}
                  student_count={data?.student_count}
                  btn={contentData?.show_more}
                />
              )
            })
          }

        </section>

        <section>
          <div className='container'>

            <SectionHeader title={contentData?.sh_our_results} />

            <div className=' grid grid-cols-4 pt-[32px] gap-[16px] max-[1255px]:grid-cols-3 max-[900px]:grid-cols-2 max-[580px]:grid-cols-1'>

              {
                englishExamResults?.map((data, i) => {
                  return (
                    <Card27
                      key={i}
                      first_name={data?.student?.first_name}
                      full_name={data?.student?.full_name}
                      last_name={data?.student?.last_name}
                      title={data?.exam?.lesson?.title}
                      score={data?.score}
                      company={data?.student?.company?.name}
                      company_id={data?.student?.company_id}
                      date={data?.created_at}
                      image_full_url={data?.student?.avatar_full_url}
                      url={data?.certificate?.certificate_full_url}

                    />
                  )
                })
              }
            </div>

            {
              englishExamResults?.length >= 8 ?
                <LoadMoreBtn onLoadData={this.loadMoreExamResults} defCount={8} count={englishExamResults?.length} /> : null
            }

          </div>

        </section>

        <section className='mt-[52px] bg-[#f9fbfb]  pb-[52px]'>
          <SectionHeader title={contentData?.students_say} />
          <StudentReviews grid_cols='grid-cols-3' />
        </section>

      </div>
    )
  }
}
const mapStateToProps = (state) => ({
  langPrepationData: state.Data.langPrepationData,
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
  englishExamResults: state.Data.englishExamResults,
});
const mapDispatchToProps = { getLangPrepationData, getstudentsReviewsData, getEnglishExamResults }
export default connect(mapStateToProps, mapDispatchToProps)(LanguagePreparation)
