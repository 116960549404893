import React, { Component } from 'react'
import { connect } from 'react-redux';
import error_img from '../images/error_image.png'

export class ErorPage extends Component {
  render() {
    const {contentData} = this.props;
    return (
      <div className='w-[100%] h-[60vh]  flex justify-center items-center max-[400px]:h-[40vh] '>
        <div className='flex flex-col h-[100%] justify-center '>

            {/* <h3 className='text-center text-[var(--blue)] font-[700] text-[80px]'>{contentData?.error}</h3>
            <h3 className='text-center text-[var(--blue)] font-[700] text-[80px] '>404</h3>
            <p className='text-center text-[20px] font-[500] text-[var(--blue)]'>{contentData?.not_page}</p>

            <div className='mt-[16px] flex gap-[20px] items-center w-full justify-center '>  

              <a href='/' className='p-[8px_14px] border-[1px] rounded-[50px] border-[var(--blue)] font-[700] bg-[var(--blue)] text-[#fff]'>{contentData?.home_page}</a>

              <a href='/contactus/' className='p-[8px_14px] border-[1px] rounded-[50px] border-[var(--blue)] font-[700] bg-[var(--blue)] text-[#fff]'>{contentData?.nav_6}</a>

            </div> */}

            <img className='max-w-[600px] max-h-[600px] max-[600px]:w-[100%] max-[600px]:h-[auto]' src={error_img} />
            <p className='text-center text-[20px] font-[500] text-[var(--blue)] max-[400px]:text-[14px]'>{contentData?.not_page}</p>

            <div className='mt-[16px] flex gap-[20px] items-center w-full justify-center '>  
              <a href='/' className='p-[8px_14px] border-[1px] text-[18px] rounded-[50px] border-[var(--blue)] font-[700] bg-[var(--blue)] text-[#fff] max-[400px]:text-[14px]'>{contentData?.home_page}</a>
              <a href='/contactus/' className='p-[8px_14px] border-[1px] text-[18px] rounded-[50px] border-[var(--blue)] font-[700] bg-[var(--blue)] text-[#fff] max-[400px]:text-[14px]'>{contentData?.nav_6}</a>
            </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData
});

export default connect(mapStateToProps)(ErorPage)