import React, { Component } from 'react'

export class Phone2Svg extends Component {
  render() {
    const {width, height, fill}=this.props;
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
 
    >
      <path
        fill={fill}
        d="M13.26 1.88a.751.751 0 0 1 .861-.62c.026.005.107.02.15.03.085.019.204.048.352.091.297.087.712.23 1.21.457.996.457 2.321 1.256 3.697 2.632 1.376 1.376 2.175 2.701 2.632 3.698.228.497.37.912.457 1.209a5.653 5.653 0 0 1 .113.455l.005.03a.765.765 0 0 1-.617.879.75.75 0 0 1-.86-.617 4.208 4.208 0 0 0-.082-.327 7.394 7.394 0 0 0-.38-1.004c-.39-.85-1.09-2.025-2.328-3.262-1.238-1.238-2.412-1.94-3.263-2.33a7.394 7.394 0 0 0-1.003-.38 5.76 5.76 0 0 0-.318-.08.759.759 0 0 1-.626-.86Z"
      />
      <path
        fill={fill}
        fillRule="evenodd"
        d="M13.486 5.33a.75.75 0 0 1 .927-.515l-.206.72.206-.72h.003l.003.002.008.002.02.006c.015.005.034.011.056.02.045.015.104.038.177.069.146.062.345.159.59.303.49.29 1.157.771 1.942 1.556.785.785 1.266 1.453 1.556 1.943.145.244.241.443.304.59a2.969 2.969 0 0 1 .088.233l.007.02.002.007v.003l.001.002-.72.207.72-.206a.75.75 0 0 1-1.439.423l-.003-.011a3.67 3.67 0 0 0-.25-.504c-.224-.377-.627-.946-1.326-1.646-.7-.7-1.27-1.103-1.646-1.326a3.662 3.662 0 0 0-.504-.25l-.011-.004a.75.75 0 0 1-.505-.924ZM5.007 4.407c1.68-1.68 4.516-1.552 5.686.544l.649 1.163c.763 1.369.438 3.096-.68 4.228a.63.63 0 0 0-.104.336c-.013.256.078.849.997 1.767.918.918 1.51 1.01 1.767.997a.63.63 0 0 0 .337-.103c1.131-1.119 2.859-1.444 4.227-.68l1.163.649c2.096 1.17 2.224 4.005.544 5.685-.899.898-2.093 1.697-3.498 1.75-2.08.079-5.536-.458-8.958-3.88-3.421-3.422-3.959-6.877-3.88-8.958.053-1.404.852-2.6 1.75-3.498Zm4.376 1.275c-.6-1.073-2.21-1.32-3.315-.214-.775.775-1.28 1.63-1.312 2.494-.066 1.735.363 4.76 3.442 7.84 3.08 3.08 6.105 3.508 7.84 3.442.863-.032 1.72-.537 2.494-1.312 1.106-1.106.86-2.715-.214-3.314l-1.163-.65c-.723-.403-1.74-.265-2.453.448-.07.07-.516.486-1.307.524-.81.04-1.791-.324-2.9-1.434-1.111-1.11-1.475-2.091-1.435-2.902.038-.791.455-1.237.524-1.306.714-.714.851-1.73.448-2.453l-.65-1.163Z"
        clipRule="evenodd"
      />
    </svg>
        )
  }
}

export default Phone2Svg