import React, { Component } from 'react'

export class LearningSvg1 extends Component {
  render() {
    const{fill} = this.props;
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={19}
        fill="none"
      >
        <path
          fill={fill}
          d="M15.093 14.697c0 .395.015.768-.005 1.14-.016.307-.197.54-.426.733-.475.4-1.041.616-1.624.792-1.522.459-3.047.91-4.57 1.363-.023.007-.048.01-.088.017v-.728c0-1.86-.005-3.72.005-5.581.001-.307.036-.62.105-.92.138-.597.52-.995 1.114-1.148 1.654-.426 3.255-1.006 4.842-1.629.21-.082.42-.167.642-.255v1.999c-.135.026-.268.04-.393.078-.627.183-1 .683-1 1.335-.001.468-.003.936 0 1.404.004.711.472 1.25 1.177 1.363.069.01.137.022.22.036h.001ZM7.997 18.753c-.46-.144-.897-.287-1.337-.418-1.19-.356-2.384-.701-3.573-1.065a3.826 3.826 0 0 1-1.278-.647c-.324-.258-.516-.577-.49-1.012.018-.302.003-.606.003-.917.2-.025.391-.032.573-.074a1.282 1.282 0 0 0 1.02-1.269c.005-.506.005-1.012 0-1.518a1.322 1.322 0 0 0-1.27-1.325c-.1-.006-.2-.001-.3-.001-.012-.047-.021-.07-.021-.094l-.001-1.927c.08.028.141.048.201.072 1.57.631 3.15 1.231 4.787 1.674.121.033.243.064.366.095.78.2 1.257.748 1.284 1.558.033.992.03 1.985.035 2.978.005 1.208.001 2.417.001 3.625v.265ZM11.42 3.212a3.197 3.197 0 0 1-3.186 3.203A3.209 3.209 0 0 1 5 3.195C4.998 1.455 6.444.003 8.184 0c1.808-.002 3.234 1.414 3.236 3.212ZM12.07 8.848c-.457.151-.902.307-1.353.445-.392.12-.788.226-1.185.328-.529.136-.983.39-1.33.814-.018-.004-.035-.003-.04-.01-.471-.588-1.148-.761-1.833-.941-.651-.171-1.29-.392-1.954-.597.52-.797 1.207-1.308 2.106-1.514.22-.05.45-.077.675-.08.734-.01 1.468-.021 2.201 0 1.133.034 2.015.547 2.668 1.466.014.02.022.044.045.089Z"
        />
        <path
          fill="#264EA9"
          d="M2.304 12.592c0 .164.001.329 0 .493-.006.501-.358.865-.849.878a.856.856 0 0 1-.886-.846 13.717 13.717 0 0 1 0-1.06c.02-.51.408-.867.9-.85.484.016.832.389.834.893v.492ZM14.259 12.567c0-.164-.003-.329 0-.493.008-.477.335-.83.802-.867.45-.035.878.284.916.745.035.426.03.86-.01 1.284-.043.44-.472.759-.897.724a.86.86 0 0 1-.81-.844c-.004-.183 0-.366 0-.55h-.001Z"
        />
      </svg>
    )
  }
}

export default LearningSvg1