import React, { Component } from 'react'
import GraduationSvg from '../svg/GraduationSvg';
import Location from '../svg/LocationSvg';
import { Link } from 'react-router-dom';
import rank1 from "../../images/rankings/rank1.png"
import rank2 from "../../images/rankings/rank2.png";
import rank3 from "../../images/rankings/rank3.png";
import rank4 from "../../images/rankings/rank4.png";
import { getCompanyPath } from '../../actions/company_path';
export class Card20 extends Component {

  getNameText(first_name, last_name) {
    const firstInitial = first_name ? first_name.charAt(0) : '';
    const lastInitial = last_name ? last_name.charAt(0) : '';
    return firstInitial + lastInitial;
  }

  getRandImage() {
    let images = [rank1, rank2, rank3, rank4];
    let rand = Math.floor(Math.random() * 4)
    return images[rand]
  }

  getStr(text) {
    // console.log(text)
    return text.replace(/<[^>]+>/g, ' ');
  }


  splitText(text) {
    // console.log(text)
    if (text.length > 30) {
      return text.substring(0, 30) + "..."
    } else {
      return text
    }
  }

  getName(name) {
    const splt = name.split(' ');
    let txt = '';
    if (splt.length > 0) {
      txt += splt[0][0].toUpperCase(); // İlk kelimenin ilk harfi
      if (splt.length > 1) {
        txt += splt[splt.length - 1][0].toUpperCase(); // Son kelimenin ilk harfi
      }
    }
    return txt;
  }


  getStr(text) {
    if (text) {
      return text.replace(/<[^>]+>/g, ' ');
    }
    return '';
  }

  splitText(text) {
    if (text) {
      if (text.length > 30) {
        return text.substring(0, 30) + "...";
      } else {
        return text;
      }
    }
    return '';
  }


  getLangCode = () => {
    const langId = localStorage.getItem('language'); // Dili buradan alıyoruz
    return langId === '1' ? '' : '/en';
  };
  render() {
    const {
      first_name,
      image_full_url,
      user_image = "",
      last_name,
      company_name,
      country_image,
      country_name,
      program_name,
      inst_name,
      ranking,
      company_id,
      amount,
      currency
    } = this.props;

    const langCode = this.getLangCode(); // Dinamik dil kodunu al
    return (
      <div className="rounded-[8px_8px_8px_8px] pb-[8px] bg-[#fff] shadow m-[10px] relative">
        {/* <div className="flex justify-end mr-[15px] rounded-[8px_8px_0px_0px] h-[56px] absolute z-40 w-full">
          {
              ranking <=500?
              <div className='relative flex justify-center items-center mr-[10px]  mt-[10px]'>
                <img style={{ width: '50px' }} src={this.getRandImage(ranking)} />
                <span className='absolute text-[20px] text-white font-black mt-[9px] different-font'>{ranking}</span>
              </div>:null
          }
        </div> */}

        <div className="flex justify-between items-center mr-[15px] rounded-[8px_8px_0px_0px] h-[60px] absolute z-40 w-full">
          {amount || currency ? (
            <div className='ml-[10px] bg-[var(--blue)] rounded-[4px] flex items-center p-[2px_4px]'>
              <span className='text-[var(--white)] text-[12px] font-bold'>{amount} {currency} Scholarship</span>
            </div>) : <div></div>
          }

          {
            ranking <= 500 ?
              <div className='relative flex justify-center items-center mr-[10px] mt-[10px]'>
                <img style={{ width: '50px', height: '55px' }} src={this.getRandImage(ranking)} />
                <span className='absolute text-[20px] text-white font-black mt-[9px] different-font'>{ranking}</span>
              </div> : null
          }
        </div>
        <div className="background-image-cont relative">
          <img className="w-full h-full rounded-[8px_8px_0_0]" src={image_full_url} />
        </div>
        <div className="p-[0_15 px_15px_15px]">
          <div className="pl-[10px] flex flex-col gap-[5px] w-297 clip-custom bg-[var(--white)] rounded-[0px_0px_8px_8px]">
            {user_image !== "" ? (
              <img
                className="absolute w-[80px] h-[80px] rounded-[50px] border-[4px] border-white mt-[-90px]"
                src={user_image}
              />
            ) : (
              <span className="w-[80px] h-[80px] rounded-[50px] inline-flex justify-center items-center text-white text-[27px] font-[500] bg-blue-700">
                {this.getNameText(first_name, last_name)}
              </span>
            )}
            <div className="p-[8px] flex flex-col gap-[10px]">
              <div className="flex items-center gap-[4px]">
                <h3 className="font-[700] text-[18px] text-[var(--blue)]">{this.getName(first_name)}.</h3>
                <h3 className="font-[700] text-[18px] text-[var(--blue)]">{last_name}</h3>
              </div>
              <section>
                <div className="flex flex-col gap-[8px] ">
                  <p className="text-[14px] font-[700] text-[#000]" title={inst_name} >{this.splitText(this.getStr(inst_name))}</p>
                  <div className="flex flex-col gap-[6px] ml-[5px]">
                    <div className="flex items-center gap-[12px] ml-[-4px]">
                      <GraduationSvg />
                      <span className="text-[12px] font-[500] text-[black]" title={program_name} >{this.splitText(this.getStr(program_name))}</span>
                    </div>
                    <div className="flex items-center gap-[16px]">
                      <img className="w-[20px] h-[12px]" src={country_image} />
                      <span className="text-[12px] font-[500] text-[black]" title={country_name}>{country_name}</span>
                    </div>

                  </div>

                  <div className="flex items-center ml-[1px] gap-[10px] ">
                    <Location width="24" height="24" fill="var(--blue)" />
                    <Link to={`${langCode}${getCompanyPath(company_id)}`} className="text-[#0066FF] text-[14px] font-[500]">
                      {company_name}
                    </Link>
                  </div>
                </div>

              </section>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default Card20