import React, { Component } from 'react';
import RightArrowSvg from '../svg/RightArrowSvg';
import { connect } from 'react-redux';
import { getKidsEnglishType } from '../../actions/MainAction';
import DiplomsPopup from '../DiplomsPopup';

export class Card19 extends Component {
    state = {
        showPopup: false,
    };


    togglePopup = () => {
        this.setState((prevState) => ({ showPopup: !prevState.showPopup }));
    };

    render() {
        const { showPopup } = this.state;
        const { lvl_diploma, image, kidsEnglishTypeData, text, contentData } = this.props;
        return (
            <div className='shadow-lg p-[26px_32px] flex flex-col gap-[32px]  rounded-[8px] bg-[#fff]'>


                <div className='w-full'>
                    <img src={image} className='w-full h-[200px] rounded-[100px]' />
                </div>

                <div className='flex flex-col gap-[15px]'>
                    <h3 className='font-[600] text-[24px] ml-[4px] text-[#313e3b]'>
                        {lvl_diploma}
                    </h3>


                    <div className='flex flex-col justify-between bg-[#fff] p-[0px_10px]'>
                        <div className=' card19text h-[230px]' dangerouslySetInnerHTML={{ __html: text }}>

                        </div>

                        <div className='flex justify-center'>
                            <button onClick={this.togglePopup} className='border-[2px] p-[10px_25px] border-[var(--blue)] rounded-[50px] flex justify-center gap-[10px] items-center'>
                                <span className='font-[700] text-[16px] text-[var(--blue)]'>{contentData?.get_information}</span>
                                <RightArrowSvg fill='var(--blue)' width='20' height='20' />
                            </button>
                            <DiplomsPopup show={showPopup} onClose={this.togglePopup}></DiplomsPopup>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    kidsEnglishTypeData: state.Data.kidsEnglishTypeData,
    contentData: state.Data.contentData,
    lang_id: state.Data.lang_id,

});

const mapDispatchToProps = { getKidsEnglishType };

export default connect(mapStateToProps, mapDispatchToProps)(Card19);