import React, { Component } from 'react'
import MasterSvg from '../svg/MasterSvg'
import LocationSvg from '../svg/LocationSvg'
import { connect } from 'react-redux';
import StudyAbroadPopup from '../StudyAbroadPopup';

export class Card18 extends Component {

  state = {
    showPopup: false,
  };


  togglePopup = () => {
    this.setState((prevState) => ({ showPopup: !prevState.showPopup }));
  };


  render() {
    const {showPopup} = this.state
    const {name, degree, uni, country, contentData} = this.props;
    return (
      <div className='bg-[#fff] shadow-md p-[15px] rounded-[10px]'>
        <div className='flex flex-col gap-[17px] p-[13px_0] border-dashed border-b-[1px] border-[#000]'>
            <h3 className='text-[15px] font-[600] text-[#000] '>
                {name}
            </h3>

            <div className='flex items-center gap-[15px]'>
                <div className='flex items-center gap-[10px]'>
                    <MasterSvg />
                    <span className='text-[11px] text-[#7D7D7D] font-[300]'>{degree}</span>
                </div>

                <div className='flex items-center gap-[10px]'>
                    <LocationSvg width='16' height='16' fill='#7d7d7d' />
                    <span className='text-[11px] text-[#7D7D7D] font-[300]'>{uni}</span>
                </div>

                {/* <span>{country}</span> */}
            </div>
            
        </div>

        <div className='pt-[13px] flex justify-end'>
            {/* <span className='text-[16px] font-[500] text-[#000]'>Starting from: 0 USD</span> */}

          <button onClick={this.togglePopup} className='border-[1px] border-[#4F46E5] p-[4px_8px] rounded-[50px] font-[500] text-[14px] text-[#4F46E5]'>{contentData?.video_sc_enroll}</button>
          <StudyAbroadPopup  show={showPopup} degree={degree} country_name={country} universty_name={uni} program={name} onClose={this.togglePopup}></StudyAbroadPopup>
        </div>
      </div>
    )
  }
}

const mapStateToProps =(state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
})

export default connect(mapStateToProps) (Card18)