import React, { Component } from 'react'

export class MinusSvg extends Component {
  render() {
    return (
        <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={24}
    fill="none"
  >
    <path
      fill="#000"
      d="M4 12a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Z"
    />
  </svg>
    )
  }
}

export default MinusSvg