import React, { Component } from 'react';
import Card4 from '../components/cards/Card4';
import { getstudentsReviewsData } from '../actions/MainAction';
import { connect } from 'react-redux';

export class StudentReviews extends Component {
  componentDidMount() {
    this.props.getstudentsReviewsData(this.props.lang_id, this.props.dataCount, "");
  }
    
  shuffleArray = (array) => {
    // Verilen bir diziyi karıştıran fonksiyon
    const shuffledArray = [...array];
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]];
    }
    return shuffledArray;
  };

  render() {
    const { studentsReviewsData, contentData, grid_cols } = this.props;
    // console.log(studentsReviewsData);
    // Sadece ilk `dataCount` kadar öğrenci değerlendirmesini al ve karıştır
    const firstReviews = this.shuffleArray(studentsReviewsData.slice(0, this.props.dataCount));
    return (
      <div className='pt-[32px] pb-[52px]'>
        <div className={`${grid_cols} grid container  gap-[40px] max-[900px]:grid-cols-2 max-[550px]:grid-cols-1`}>
          {firstReviews?.map((data, i) => (
            <Card4
              key={i}
              image={data?.image_full_url}
              name={data?.name}
              text={data?.text}
              raiting={data?.raiting}
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  studentsReviewsData: state.Data.studentsReviewsData,
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
});

const mapDispatchToProps = { getstudentsReviewsData };

export default connect(mapStateToProps, mapDispatchToProps)(StudentReviews);