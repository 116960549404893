import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CloseSvg from './svg/CloseSvg';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import PhoneSvg4 from './svg/PhoneSvg4';
import UserSvg1 from './svg/UserSvg1';
import { postStatistics } from '../actions/MainAction';
import { changeStateValue } from '../redux/MainReducer';
import { connect } from 'react-redux';
//
import {validatePhoneNumberLength, isValidPhoneNumber, isPossiblePhoneNumber} from 'libphonenumber-js'
class DiplomsPopup extends Component {
  state = {
    name: '',
    surname: '',
    education_type: null,
    company_id: '',
    subject: '',
    phone: '',
    contacsPage: null,
    errors: {},
    successMessage: '',
    style: 1,
    btnDisable: false,
    countryCode:'az'
  };

  onClickPageSchool = (index) => {
    let education_type = index === 1 ? 1 : 2;
    let school_type = '';

    switch (index) {
      case 3:
        school_type = 'private_school';
        break;
      case 4:
        school_type = 'secondary_school';
        break;
      case 5:
        school_type = 'primary_school';
        break;
      default:
        school_type = '';
    }

    this.setState({ contacsPage: index, education_type, school_type });
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangePhone = (phone, country) => {
    this.setState({ phone });
    this.getValidNumber(phone, country?.countryCode)
  };

  handleSubmit = () => {
    const { name, education_type, company_id, subject, phone, school_type, surname } = this.state;
    const { postStatistics, contentData, phoneValidFormat } = this.props;
    let errors = {};

    if (!phoneValidFormat) {
      errors.phone = `${contentData?.enter_phone_number}`;
    }
    if (!name) {
      errors.name = `${contentData?.enter_name}`;
    }
    if (!surname) {
      errors.surname = `${contentData?.enter_surname}`;
    }
    if (education_type === null) {
      errors.education_type = `${contentData?.enter_school}`;
    }
    if (!phone) {
      errors.phone = `${contentData?.enter_phone_number}`;
    }
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    const data = {
      name: `${name} ${surname}`,
      education_type,
      school_type,
      phone_number: phone
    };

    this.setState({ btnDisable: true });

    postStatistics(data)
      .then((resp) => {
        this.setState({ btnDisable: false });
        if (resp === "success") {
          this.props.changeStateValue({ name: "popUpVisible", value: true });
          this.props.onClose();
          this.setState({
            name: '',
            surname: '',
            education_type: null,
            company_id: '',
            subject: '',
            phone: '',
            contacsPage: null,
            errors: {},
            successMessage: '',
            style: 1
          });
        }
      })
      .catch((error) => {
        this.setState({ successMessage: '', errors: { form: 'Form gönderimi başarısız!' } });
      });
  };
  getValidNumber(phone, code){
    const phoneNumber = isValidPhoneNumber(phone, code.toUpperCase());
    this.props.changeStateValue({name:"phoneValidFormat", value:phoneNumber})
    // return phoneNumber
   }
  render() {
    const { name, contacsPage, company_id, subject, phone, errors, successMessage, style, school_type, showPopup, surname, btnDisable } = this.state;
    const { show, onClose, children, contentData, styleprp, phoneValidFormat } = this.props;

    if (!show) {
      return null;
    }
    // console.log(phoneValidFormat)
    return (
      <div>
        <div className='popup-bg-thanks' onClick={onClose}>
          <div className='popup-thanks max-w-[620px] m-[16px] rounded-[8px]' onClick={(e) => e.stopPropagation()}>
            {children}
            <div className='flex justify-between border-b-[2px]'>
              <div></div>
              <div className='flex justify-between w-[68%]'>
                <h3 className='font-[600] text-[#032552] p-[22px_0px] text-[24px] text-center max-w-[500px]'>
                  {contentData?.popup_information_questionnaire}
                </h3>
                <div className='flex items-start'>
                  <button onClick={onClose} className='m-[7px]'>
                    <CloseSvg />
                  </button>
                </div>
              </div>
            </div>

            <div className='p-[12px] grid grid-cols-2 gap-[20px]'>
              <div className='flex flex-col gap-[8px]'>
                <div className='flex items-center gap-[5px]'>
                  <UserSvg1 />
                  <span className='text-[14px] font-[550] text-[#000]'>{contentData?.popup_name}</span>
                  {errors.name && <span className='text-red-500 text-[12px]'>{errors.name}</span>}
                </div>
                <div className='flex flex-col'>
                  <div className='flex items-center border-[1px] rounded-[8px] p-[10px]'>
                    <input
                      className='w-full'
                      placeholder={contentData?.popup_name}
                      name='name'
                      value={name}
                      onChange={this.handleChange}
                      id='name'
                    />
                  </div>
                </div>
              </div>

              <div className='flex flex-col gap-[8px]'>
                <div className='flex items-center gap-[5px]'>
                  <UserSvg1 />
                  <span className='text-[14px] font-[550] text-[#000]'>{contentData?.popup_surname}</span>
                  {errors.surname && <span className='text-red-500 text-[12px]'>{errors.surname}</span>}
                </div>
                <div className='flex flex-col'>
                  <div className='flex items-center border-[1px] rounded-[8px] p-[10px]'>
                    <input
                      className='w-full'
                      placeholder={contentData?.popup_surname}
                      name='surname'
                      value={surname}
                      onChange={this.handleChange}
                      id='surname'
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='p-[0px_12px] flex flex-col gap-[10px]'>
              <div className='flex items-center gap-[5px]'>
                <span className='text-[#072465] text-[14px] font-[600]'>{contentData?.popup_enter_school}</span>
                {errors.education_type && <span className='text-red-500 text-[12px]'>{errors.education_type}</span>}
              </div>
              <div className='grid grid-cols-3 gap-[8px] w-full max-[460px]:grid-cols-1'>
                <button id='school1' onClick={() => this.onClickPageSchool(3)}
                  className={`${contacsPage === 3 ? "text-white bg-[#264EA9]" : "text-[#072465] border-[#92B3FF]"} p-[8px_10px] rounded-[50px] border-[1px] text-[15px] font-[500]`}>
                  {contentData?.high_scholl}(9-11)
                </button>
                <button id='school2' onClick={() => this.onClickPageSchool(4)}
                  className={`${contacsPage === 4 ? "text-white bg-[#264EA9]" : "text-[#072465] border-[#92B3FF]"} p-[8px_10px] rounded-[50px] border-[1px] text-[15px] font-[500]`}>
                  {contentData?.secondary_school}(6-8)
                </button>
                <button id='school3' onClick={() => this.onClickPageSchool(5)}
                  className={`${contacsPage === 5 ? "text-white bg-[#264EA9]" : "text-[#072465] border-[#92B3FF]"} p-[8px_10px] rounded-[50px] border-[1px] text-[15px] font-[500]`}>
                  {contentData?.primary_school}(1-5)
                </button>
              </div>
            </div>

            <div className='p-[0px_12px] mt-[15px] gap-[24px]'>
              <div className='flex flex-col w-full gap-[5px]'>
                <div className='flex flex-col gap-[5px]'>
                  <div className='flex items-center gap-[5px]'>
                    <PhoneSvg4 />
                    <span className='text-[14px] font-[550] text-[#000]'>{contentData?.phone_number}</span>
                  </div>
                  {errors.phone && <span className='text-red-500 text-[12px]'>{errors.phone}</span>}
                </div>
                <div className='border-[1px] w-full rounded-[8px] p-[3px]'>
                  <PhoneInput
                    containerStyle={{ border: "none", margin: "0" }}
                    inputStyle={{
                      backgroundColor: "#f2f2f2",
                      padding: '0px',
                      marginLeft: '38px',
                      borderRadius: "5px",
                      border: 'none',
                      background: 'none'
                    }}
                    buttonStyle={{ backgroundColor: "transparent", border: "none" }}
                    dropdownStyle={{ borderRadius: '0px' }}
                    country={'az'}
                    value={phone}
                    onChange={this.handleChangePhone}
                    className="custom-flag"
                  />
                </div>
              </div>
            </div>

            <div>
              <div className='flex justify-center w-full p-[0px_12px] mt-[32px] mb-[20px]'>
                <button disabled={btnDisable} onClick={this.handleSubmit} className='bg-[#EF7000] w-full p-[12px_0px] text-white rounded-[8px] text-[15px] font-[400]'>
                  {contentData?.get_information}
                </button>
              </div>
              {successMessage && (
                <div className='text-green-500 text-center mb-[20px]'>{successMessage}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DiplomsPopup.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  contentData: PropTypes.object,
  postStatistics: PropTypes.func.isRequired,
  phoneValidFormat: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
  phoneValidFormat: state.Data.phoneValidFormat,
});

const mapDispatchToProps = { postStatistics, changeStateValue };

export default connect(mapStateToProps, mapDispatchToProps)(DiplomsPopup);