import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CloseSvg from './svg/CloseSvg';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import {  postStatistics } from '../actions/MainAction';
import UserSvg1 from './svg/UserSvg1';
import SubjectSvg1 from './svg/SubjectSvg1';
import LearningSvg1 from './svg/LearningSvg1';
import PhoneSvg4 from './svg/PhoneSvg4';
import { changeStateValue } from '../redux/MainReducer';
//
import {validatePhoneNumberLength, isValidPhoneNumber, isPossiblePhoneNumber} from 'libphonenumber-js'

class PopupComponent extends Component {
  state = {
    name: '',
    company_id: '',
    subject: '',
    phone: '',
    contacsPage: null,
    errors: {},
    successMessage: '',
    showSuccessPopup: false, // Başarı mesajı popup'ı için state
    btnDisable:false,
    countryCode:'az'
  };

  togglePopup = () => {
    this.setState((prevState) => ({ showPopup: !prevState.showPopup }));
  };

  // onClickPageSchool = (index) => {
  //   let education_type = index === 1 ? 1 : 2;
  //   this.setState({ contacsPage: index, education_type });
  // };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleChangePhone = (phone, country) => {
    this.setState({ phone })
    this.getValidNumber(phone, country?.countryCode)
  };

  handleSubmit = () => {
    const { name, company_id, subject, phone } = this.state;
    const { postStatistics, phoneValidFormat, contentData } = this.props;
    let errors = {};

    if (!name) {
      errors.name = 'Adınızı qeyd edin';
    }
    // if (education_type === null) {
    //   errors.education_type = 'Necə oxumaq istədiyinizi qeyd edin';
    // }
    if (!company_id) {
      errors.company_id = 'Bir mərkəz seçin';
    }
    if (!subject) {
      errors.subject = 'Oxumaq istədiyiniz fənni seçin';
    }
    if (!phoneValidFormat) {
      errors.phone = `${contentData?.enter_phone_number}`;
    }

    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    const data = {
      name,
      // education_type,
      company_id:  company_id,
      subject,
      phone_number: phone
    };
    this.setState({btnDisable:true})

    postStatistics(data)
      .then((resp) => {
        console.log(data);
        // this.setState({ btnDisable: false });
        if(resp === "success"){
          this.setState({ successMessage: '', errors: {}, showSuccessPopup: true });
          this.props.changeStateValue({name:"popUpVisible", value:true})
          this.props.onClose();
          this.setState({btnDisable:false})
        }else{
          // getmedi
        }
        // console.log(resp)
        
      })
      .catch((error) => {
        this.setState({ successMessage: '', errors: { form: 'Form submission failed!' } });
      });
  };

  getValidNumber(phone, code){
    const phoneNumber = isValidPhoneNumber(phone, code.toUpperCase());
    this.props.changeStateValue({name:"phoneValidFormat", value:phoneNumber})
    // return phoneNumber
   }

  render() {
    const { name, contacsPage, company_id, subject, phone, errors, successMessage, showSuccessPopup, btnDisable } = this.state;
    const { show, onClose, children, contentData, styleprp, phoneValidFormat } = this.props;

    if (!show) {
      return null;
    }


    // console.log(phoneValidFormat)
    return (
      <div>
        <div className='popup-bg ' onClick={onClose}>
          <div className='popup rounded-[8px] max-w-[578px] flex flex-col gap-[5px] m-[16px]' onClick={(e) => e.stopPropagation()}>
            {children}
            <div className='flex justify-between border-b-[2px] '>
              <div></div>
              <div className='flex justify-between w-[68%] max-[380px]:w-[73%]'>
                <h3 className='font-[600] text-[#032552] p-[22px_0px] text-[24px] text-center max-w-[500px] max-[490px]:text-[18px] max-[400px]:p-[14px_0px]'>
                  {contentData?.popup_registration}
                </h3>
                <div className='flex items-start '>
                  <button onClick={onClose} className='m-[7px]'>
                    <CloseSvg />
                  </button>
                </div>
              </div>
            </div>
            <div className='p-[12px] grid grid-cols-2 gap-[20px] max-[400px]:grid-cols-1 max-[400px]:gap-[10px]'>
              <div className='flex flex-col gap-[5px]'>
                <div className='flex items-center gap-[5px]'>
                  <div>
                    <UserSvg1 />
                  </div>
                  <span className='text-[14px] font-[550] text-[#000]'>{contentData?.popup_name}</span>
                  
                </div>
                {errors.name && <span className='text-red-500 text-[12px]'>{errors.name}</span>}
                <div className='flex flex-col'>
                  <div className='flex items-center border-[1px] border-[#d3cfcf] rounded-[8px] p-[10px]'>
                    <input
                      className='w-full'
                      placeholder={contentData?.popup_name}
                      name='name'
                      value={name}
                      onChange={this.handleChange}
                      id='name'
                    />
                  </div>
                </div>
              </div>
              <div className='flex flex-col gap-[5px]'>
                <div className='flex items-center gap-[5px]'>
                  <div>
                    <SubjectSvg1 />
                  </div>
                  <span className='text-[14px] font-[550] text-[#000]'>{contentData?.popup_subject}</span>
                  
                </div>
                {errors.subject && <span className='text-red-500 text-[12px]'>{errors.subject}</span>}
                <div className='w-full'>
                  <select
                    className='w-full p-[10px] font-[500] text-[16px] border-[1px] border-[#d3cfcf] rounded-[8px]'
                    name='subject'
                    value={subject}
                    onChange={this.handleChange}
                  >
                    <option value=''>Seçin</option>
                    <option value='Beynəlxalq məktəb diplomu'>{contentData?.popup_school_diploms}</option>
                    <option value='Ümumi ingilis dili'>{contentData?.popup_general_ingilsh}</option>
                    <option value='IELTS hazırlığı'>{contentData?.popup_ielts_prepation}</option>
                    <option value='TOEFL hazırlığı'>{contentData?.popup_toefl_prepation}</option>
                    <option value='Duolingo hazırlığı'>{contentData?.popup_duolingo_prepation}</option>
                    <option value='SAT hazırlığı'>{contentData?.popup_sat_prepation}</option>
                    <option value='Riyaziyyat hazırlığı'>{contentData?.popup_math_prepation}</option>
                    <option value='Olimpiada hazırlığı'>{contentData?.popup_olympcs_prepation}</option>
                    <option value='Uşaqlar üçün hazırlıq'>{contentData?.popup_kids_prepation}</option>
                    <option value='Digər sahələr'>{contentData?.popup_other_select}</option>
                  </select>
                </div>
              </div>
            </div>
            <div className='p-[0px_12px] flex flex-col gap-[5px]'>
              {/* <div className='flex flex-col gap-[5px]'>
                <div className='flex items-center gap-[5px]'>
                  <span className='text-[#072465] text-[14px] font-[600]'>
                    {contentData?.how_do_you_want_to_read}
                  </span>
                  {errors.education_type && <span className='text-red-500 text-[12px]'>{errors.education_type}</span>}
                </div>
                <div className='grid grid-cols-2 gap-[24px] w-full max-[400px]:grid-cols-1 max-[400px]:gap-[18px]'>
                  <button
                    onClick={() => this.onClickPageSchool(2)}
                    className={`${
                      contacsPage === 2 ? 'text-white bg-[#264EA9]' : 'text-[#072465] border-[#92B3FF]'
                    } p-[8px_0px] rounded-[50px] border-[1px] text-[15px] font-[500]`}
                  >
                    {contentData?.in_center}
                  </button>
                  <button
                    onClick={() => this.onClickPageSchool(1)}
                    className={`${
                      contacsPage === 1 ? 'text-white bg-[#264EA9]' : 'text-[#072465] border-[#92B3FF]'
                    } p-[8px_0px] rounded-[50px] border-[1px] text-[15px] font-[500]`}
                  >
                    {contentData?.popup_online}
                  </button>
                </div>
              </div> */}
              
              

              <div className='grid grid-cols-2 gap-[20px] mt-[12px] max-[400px]:grid-cols-1 max-[400px]:gap-[10px]'>
                
                <div className='flex flex-col gap-[4px]'>
                  <div className='flex items-center gap-[5px]'>
                    <div>
                      <LearningSvg1 fill='#264EA9'/>
                    </div>
                    <span className='text-[14px] font-[550] text-[#000]'>{contentData?.popup_select_center}</span>
                  </div>
                  {errors.company_id && <span className='text-red-500 text-[12px]'>{errors.company_id}</span>}
                <div>
                    
                       
                <select
                  className='w-full p-[10px] font-[500] text-[16px] border-[1px] border-[#d3cfcf] rounded-[8px]'
                  name='company_id'
                  value={company_id}
                  onChange={this.handleChange}
                  id='company_id'
                >
                  <option value=''>{contentData?.popup_choose}</option>
                  <option value='1'>{contentData?.high_school}</option>
                  <option value='2'>{contentData?.ofice_khatai}</option>
                  <option value='13'>{contentData?.office_hazi}</option>
                  <option value='11'>{contentData?.office_ingilab}</option>
                  <option value='6'>{contentData?.office_sahil}</option>
                  <option value='7'>{contentData?.office_bakikhanov}</option>
                  <option value='14'>{contentData?.office_sumgait}</option>
                  <option value='5'>{contentData?.office_icharishahar}</option>
                  <option value='9'>{contentData?.office_ajami}</option>
                  <option value='4'>{contentData?.office_28may}</option>
                  <option value='8'>{contentData?.office_nizami}</option>
                  <option value='33'>{contentData?.office_agseher}</option>
                  <option value='30'>{contentData?.office_ganjlik}</option>
                  <option value='12'>{contentData?.office_elmler}</option>
                  <option value='10'>{contentData?.office_narimanov}</option>
                </select> 
                    
                </div>
              </div> 
                
                
                


                <div className='flex flex-col w-full gap-[5px] '>
                  <div className='flex flex-col gap-[5px]'>
                    <div className='flex items-center gap-[5px]'>
                      <div>
                        <PhoneSvg4 />
                      </div>
                      <span className='text-[14px] font-[550] text-[#000]'>{contentData?.phone_number}</span>
                    </div>
                    {errors.phone && <span className='text-red-500 text-[12px]'>{errors.phone}</span>}
                  </div>
                  <div className='border-[1px] border-[#d3cfcf] w-full rounded-[8px] p-[3px]'>
                    <PhoneInput
                      containerStyle={{
                        border: 'none',
                        margin: '0',
                      }}
                      inputStyle={{
                        backgroundColor: '#f2f2f2',
                        padding: '0px',
                        marginLeft: '38px',
                        borderRadius: '5px',
                        border: 'none',
                        background: 'none',
                      }}
                      buttonStyle={{
                        backgroundColor: 'transparent',
                        border: 'none',
                      }}
                      dropdownStyle={{
                        borderRadius: '0px',
                      }}
                      country={'az'}
                      value={phone}
                      onChange={this.handleChangePhone}
                      className='custom-flag'
                    />
                  </div>
                </div>

              </div>
              



              
            </div>
            <div>
              <div className='flex justify-center w-full p-[0px_12px] mt-[32px] mb-[20px] max-[400px]:mt-[16px]'>
                <button
                  disabled={btnDisable}
                  onClick={this.handleSubmit}
                  className='bg-[#EF7000] w-full p-[12px_0px] text-white rounded-[8px] text-[15px] font-[400]'
                >
                  {contentData?.submit}
                </button>
              </div>
              {/* {successMessage && (
                <div className='text-green-500 text-center mb-[20px]'>{successMessage}</div>
              )} */}
            </div>
          </div>
        </div>
        {/* Başarı mesajı popup'ı */}
        {/* {showSuccessPopup && (
          <SuccessPopup />
        )} */}
      </div>
    );
  }
}

PopupComponent.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node,
  contentData: PropTypes.object,
  postStatistics: PropTypes.func.isRequired,
  phoneValidFormat: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,

  lang_id: state.Data.lang_id,
  phoneValidFormat: state.Data.phoneValidFormat,

});

const mapDispatchToProps = {
  postStatistics, changeStateValue
};

export default connect(mapStateToProps, mapDispatchToProps)(PopupComponent);


