import React, { Component } from 'react'

export class PhoneSvg4 extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        fill="none"
      >
        <path
          fill="#264EA9"
          d="M12.56 16c-.682-.003-1.164-.157-1.638-.337-1.255-.476-2.417-1.144-3.535-1.901-1.41-.955-2.701-2.057-3.816-3.378C2.382 8.976 1.364 7.446.586 5.746a9.323 9.323 0 0 1-.489-1.333c-.194-.68-.087-1.317.227-1.928.316-.616.76-1.114 1.275-1.55.324-.275.643-.561 1.033-.73.19-.083.387-.158.588-.194.306-.055.564.108.812.27.409.265.729.632 1.016 1.032.49.683.903 1.414 1.242 2.192.083.19.166.385.128.605-.047.274-.231.438-.435.576a5.575 5.575 0 0 1-1.124.615c-.15.058-.295.13-.44.2-.46.224-.669.823-.458 1.31a6.91 6.91 0 0 0 .932 1.528 14.428 14.428 0 0 0 4.21 3.655c.437.25.954.14 1.321-.288.078-.09.146-.193.202-.3.197-.377.383-.761.584-1.137.083-.155.185-.3.289-.441a.772.772 0 0 1 .987-.242c.477.235.908.548 1.338.86.624.454 1.225.938 1.764 1.502.08.084.151.178.223.27.246.309.216.655.11 1.005-.113.375-.327.69-.569.982-.54.656-1.161 1.208-1.923 1.564-.33.154-.676.234-.87.23V16Z"
        />
      </svg>
    )
  }
}

export default PhoneSvg4