import React, { Component } from 'react'
import triangle from '../../images/bucaq.jpg'
import RightArrowSvg from '../svg/RightArrowSvg'
import DiplomsPopup from '../DiplomsPopup';
import { connect } from 'react-redux';



export class Card29 extends Component {
  state = {
    showPopup: false,
};


togglePopup = () => {
    this.setState((prevState) => ({ showPopup: !prevState.showPopup }));
};

  render() {
    const { showPopup } = this.state;
    const{title, description, text1, text2, contentData}=this.props;
    return (
      <div className='flex flex-col  mt-[20px]  gap-[6px] w-1/2 max-[850px]:w-full'>
        
        <div className='flex flex-col gap-[6px]'>
          <div class="banner ">
            <span class="text-[20px] font-[700] text-[#fff]">{title}</span>
            <span class="arrow"></span>
          </div>
          
          {/* <div className='pl-[10px]'>
            <h4 className='font-[700] text-[20px] text-[#b1b2b2]'>A level Diplomu</h4>
          </div> */}
        </div>
        
        <div className='flex flex-col gap-[24px]'>
          <div className='flex flex-col gap-[4px] mt-[20px]'>
            <span className='text-[#333] text-[22px] font-[700]'>{description}</span>
            <div className='flex flex-col gap-[8px]'>
              <p className='text-[17px] font-[400] text-[#212529] '>
                {text1}
              </p>

              <p className='text-[17px] font-[400] text-[#212529] '>
                {text2}
              </p>
            </div>
           
          </div>
        </div>

        <div className=''>
          <button  onClick={this.togglePopup} className='flex items-center gap-[3px] bg-[var(--blue)] text-[#fff] p-[15px_30px] rounded-[50px] text-[15px] font-[700]'>
            {contentData?.get_information}
            <RightArrowSvg fill='#fff' width='20' height='20' />
          </button>
          <DiplomsPopup  show={showPopup} onClose={this.togglePopup}>
                                
          </DiplomsPopup>
        </div>

        
        
      </div>
    )
  }
}


const mapStateToProps = (state) => ({
  contentData: state.Data.contentData,
  lang_id: state.Data.lang_id,
})

export default connect(mapStateToProps)( Card29)