import React, { Component } from 'react'
import Faq from './cards/Faq'
import { connect } from 'react-redux';
import { getFaqData } from '../actions/MainAction';
import SectionHeader from './SectionHeader';

export class FaqSection extends Component {
    componentDidMount(){
        this.props.getFaqData(this.props.lang_id, this.props.page_type)
      }
  render() {
    const {faqData, faqs = [], contentData} = this.props;
    // console.log(faqData)
    return (
        <div className='flex flex-col'>
            {
                faqData.length > 0 ?
                <SectionHeader title={contentData?.faq} />:null
                 
            }
            <section className=' flex flex-col gap-[10px]'>
                {
                    faqData.map((data, i)=>{
                        return(
                            <Faq key={i} question={data?.translate?.title} answer={data?.translate?.text} />
                        )
                    })
                }
                
            </section>
        </div>

        
    )
  }
}
const mapStateToProps = (state)=>({
    faqData: state.Data.faqData,
    lang_id: state.Data.lang_id,
    contentData: state.Data.contentData,

  });
  const mapDispatchToProps = {getFaqData}
  export default connect(mapStateToProps, mapDispatchToProps)(FaqSection)
