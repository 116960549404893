import React, { Component } from 'react'
import SectionHeader from '../components/SectionHeader'
import Card6 from '../components/cards/Card6'
import { connect } from 'react-redux'
import { getstudentsReviewsData } from '../actions/MainAction'
import { getKidsEnglishType } from '../actions/MainAction'
import { getWhyUs } from '../actions/MainAction'
import StudentReviews from '../components/StudentReviews'
import KidsCompanies from '../components/KidsCompanies'


export class KidsEnglish extends Component {
  componentDidMount(){
    this.props.getKidsEnglishType(this.props.lang_id)
    this.props.getstudentsReviewsData(this.props.lang_id)
    this.props.getWhyUs(this.props.lang_id)
    window.scrollTo(0,0)
  }
  render() {
    const {kidsEnglishTypeData, studentsReviewsData, whyUsData, contentData} = this.props;
    // console.log(whyUsData)
    return (
      <div >
        <div className='container'>
          <SectionHeader title={contentData?.english_for_kids}/>
            
            <section className='grid grid-cols-3 gap-[40px] mt-[52px] max-[900px]:grid-cols-2 max-[550px]:grid-cols-1'>
            {
              kidsEnglishTypeData?.map((data, i)=>{
                return(
                  <Card6
                    key={i}
                    title={data?.translate?.title}
                    text={data?.translate?.text}
                    type={data?.translate?.type}
                  />
                )
              })
            } 

            
            </section>
        </div>
         

        <SectionHeader title={contentData?.celt_elementary}/>
          <div className='container'>
            <KidsCompanies />
          </div>
          
          <section className='mt-[52px] pb-[52px] bg-[#f9fbfb]'>
            <StudentReviews grid_cols='grid-cols-3' />
          </section>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  kidsEnglishTypeData: state.Data.kidsEnglishTypeData,
  studentsReviewsData: state.Data.studentsReviewsData,
  whyUsData: state.Data.whyUsData,
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
});
const mapDispatchToProps = {getKidsEnglishType, getstudentsReviewsData, getWhyUs}
export default connect(mapStateToProps, mapDispatchToProps) (KidsEnglish)