// import React, { Component } from 'react';
// import aze from '../../images/azerbaijan_flag.png';
// import ing from '../../images/ingiltere_flag.png';

// export class LanguageNav extends Component {
//   state = {
//     visible: false,
//     languageVisable: aze, // Varsayılan olarak Azerbaycan bayrağı
//     languageValue: '1',    // Varsayılan dil değeri (Azerbaycan)
//     languageCode: 'az',    // Varsayılan dil kodu
//   };

//   componentDidMount() {
//     // localStorage'da değer yoksa varsayılanları ayarla
//     const savedLanguage = localStorage.getItem('language') || '1';
//     const savedFlag = localStorage.getItem('flag') || aze;
//     const savedLangCode = localStorage.getItem('langCode') || 'az';

//     // Eğer localStorage boşsa, varsayılanları kaydet
//     if (!localStorage.getItem('language')) {
//       localStorage.setItem('language', '1');
//       localStorage.setItem('flag', aze);
//       localStorage.setItem('langCode', 'az');
//     }

//     this.setState({
//       languageValue: savedLanguage,
//       languageVisable: savedFlag,
//       languageCode: savedLangCode,
//     });

//     // Dil koduna göre yönlendirme yap
//     const currentPath = window.location.pathname;
//     if (savedLangCode === 'en' && !currentPath.startsWith('/en')) {
//       window.location.href = `/en${currentPath}`;
//     } else if (savedLangCode === 'az' && currentPath.startsWith('/en')) {
//       window.location.href = currentPath.replace('/en', '');
//     }
//   }

//   handleClick = (id, src, langCode) => {
//     const currentPath = window.location.pathname;
//     const cleanPath = currentPath.replace(/\/en/, '');
//     const newPath = langCode === 'en' ? `/en${cleanPath}` : cleanPath;

//     this.setState({
//       languageValue: id,
//       languageVisable: src,
//       languageCode: langCode,
//       visible: false,
//     });

//     // Dil değişikliklerini localStorage'a kaydet
//     localStorage.setItem('language', id);
//     localStorage.setItem('flag', src);
//     localStorage.setItem('langCode', langCode);

//     window.location.href = newPath;
//   };

//   render() {
//     const { visible, languageVisable } = this.state;
//     const languages = [
//       { id: '1', src: aze, code: 'az' },
//       { id: '2', src: ing, code: 'en' },
//     ];

//     return (
//       <div className='flex flex-col items-center bg-[#fff]'>
//         <div onClick={() => this.setState({ visible: !visible })} className='flex items-start relative gap-[2px] cursor-pointer'>
//           <img className='w-[40px] h-[25px] rounded-[4px]' src={languageVisable} alt="Dil Bayrağı" />
//         </div>
//         {visible && (
//           <div className='flex flex-col gap-[8px] absolute mt-[30px] p-[10px] rounded-[8px] items-center border-[1px] shadow-sm bg-[#fff]'>
//             {languages.map((language, index) => (
//               <img
//                 src={language.src}
//                 key={index}
//                 className='w-[40px] h-[25px] rounded-[4px] cursor-pointer'
//                 alt={`Dil: ${language.code}`}
//                 onClick={() => this.handleClick(language.id, language.src, language.code)}
//               />
//             ))}
//           </div>
//         )}
//       </div>
//     );
//   }
// }

// export default LanguageNav;


import React, { Component } from 'react';
import aze from '../../images/azerbaijan_flag.png';
import ing from '../../images/ingiltere_flag.png';

export class LanguageNav extends Component {
  state = {
    languageVisable: aze,
    languageValue: '1',
    languageCode: 'az',
  };

  componentDidMount() {
    const savedLanguage = localStorage.getItem('language') || '1';
    const savedFlag = localStorage.getItem('flag') || aze;
    const savedLangCode = localStorage.getItem('langCode') || 'az';

    //default
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', '1');
      localStorage.setItem('flag', aze);
      localStorage.setItem('langCode', 'az');
    }

    this.setState({
      languageValue: savedLanguage,
      languageVisable: savedFlag,
      languageCode: savedLangCode,
    });


    const currentPath = window.location.pathname;
    if (savedLangCode === 'en' && !currentPath.startsWith('/en')) {
      window.location.href = `/en${currentPath}`;
    } else if (savedLangCode === 'az' && currentPath.startsWith('/en')) {
      window.location.href = currentPath.replace('/en', '');
    }
  }

  handleLanguageChange = () => {
    const { languageCode } = this.state;
    const newLangCode = languageCode === 'az' ? 'en' : 'az';
    const newFlag = newLangCode === 'en' ? ing : aze;
    const newLangId = newLangCode === 'en' ? '2' : '1';
    const currentPath = window.location.pathname.replace(/\/en/, '');
    const newPath = newLangCode === 'en' ? `/en${currentPath}` : currentPath;

    this.setState({
      languageValue: newLangId,
      languageVisable: newFlag,
      languageCode: newLangCode,
    });

    //localStorage qeyd et
    localStorage.setItem('language', newLangId);
    localStorage.setItem('flag', newFlag);
    localStorage.setItem('langCode', newLangCode);

    window.location.href = newPath;
  };

  render() {
    const { languageVisable } = this.state;

    return (
      <div className='flex flex-col items-start bg-[#fff]'>
        <div onClick={this.handleLanguageChange} className='flex items-start relative gap-[2px] cursor-pointer'>
          <img className='w-[40px] h-[25px] rounded-[4px]' src={languageVisable} alt="flag" />
        </div>
      </div>
    );
  }
}

export default LanguageNav;