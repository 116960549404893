import React, { Component } from 'react'
import { Link } from 'react-router-dom';

export class LinkedinSvg extends Component {
  render() {
    const {width,height, fill } = this.props;
    return (
      <Link to='https://az.linkedin.com/company/celt-colleges' target='_blank'>
              <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 1920 1920"
    fill={fill}
    
  >
    <path
      fillRule="evenodd"
      d="M478.234 600.75V1920H.036V600.75h478.198Zm720.853-2.438v77.737c69.807-45.056 150.308-71.249 272.38-71.249 397.577 0 448.521 308.666 448.521 577.562v737.602h-480.6v-700.836c0-117.867-42.173-140.215-120.15-140.215-74.134 0-120.151 23.55-120.151 140.215v700.836h-480.6V598.312h480.6ZM239.099 0c131.925 0 239.099 107.294 239.099 239.099s-107.174 239.099-239.1 239.099C107.295 478.198 0 370.904 0 239.098 0 107.295 107.294 0 239.099 0Z"
    />
  </svg>
      </Link>

    )
  }
}

export default LinkedinSvg