import React, { Component } from 'react'

export class CertificateSvg extends Component {
  render() {
    return (
      <svg
      xmlns="http://www.w3.org/2000/svg"
      width={16}
      height={16}
      fill="var(dark-gray)"
      className="bi bi-award-fill"
    
    >
      <path stroke='var(--dark-gray)' fill='none' d="m8 0 1.669.864 1.858.282.842 1.68 1.337 1.32L13.4 6l.306 1.854-1.337 1.32-.842 1.68-1.858.282L8 12l-1.669-.864-1.858-.282-.842-1.68-1.337-1.32L2.6 6l-.306-1.854 1.337-1.32.842-1.68L6.331.864z" />
      <path d="M4 11.794V16l4-1 4 1v-4.206l-2.018.306L8 13.126 6.018 12.1z" />
    </svg>
    )
  }
}

export default CertificateSvg