import React, { Component } from 'react'
import { resetStateValue } from '../redux/MainReducer';
import { connect } from 'react-redux';

export class GalleryModal extends Component {
    closeModal(e){
        if(e.target.className?.includes("remove_img")){
            this.props.resetStateValue({name:'galleryModalData'})
        }
    }
  render() {
    const {galleryModalData} = this.props;
    return (
      <div onClick={this.closeModal.bind(this)} className='fixed top-0 left-0 w-full h-screen z-50 bg-[rgba(0,0,0,0.5)] flex justify-center items-center remove_img'>
        <div className='max-w-[800px] rounded-[10px] p-[10px] max-h-[50vh]'>
            <img src={galleryModalData?.image} className='w-full h-auto' />
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    galleryModalData: state.Data.galleryModalData
});
const mapDispatchToProps = {resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(GalleryModal)