import React, { Component } from 'react'
import Phone2Svg from '../svg/Phone2Svg'
import WhatsappSvg from '../svg/WhatsappSvg'
import FacebookSvg from '../svg/FacebookSvg'
import Instagram from '../svg/Instagram'
import LocationSvg from '../svg/LocationSvg'


export class Card9 extends Component {

  convertedNumber(phoneNumber=""){
    let convertedNumber = phoneNumber?.replace(/\D/g, ''); // \D matches any non-digit character
    convertedNumber = "+994" + convertedNumber?.substring(1); // Removing the leading zero
    return convertedNumber
   }

  //  convertedOfficeNumber(office_number=""){
  //   let convertedOfficeNumber = office_number.replace(/\D/g, '');
  //   convertedOfficeNumber = "+" + convertedOfficeNumber.substring(0);
  //   return convertedOfficeNumber;
  //  }
 
  convertedOfficeNumber(office_number="") {
    // office_number'ın null veya undefined olmadığından emin olun
    if (!office_number) {
        office_number = "";
    }
    // Replace işlemiyle sadece rakamları al
    let convertedOfficeNumber = office_number.replace(/\D/g, '');
    // + işareti ekleyerek numarayı dönüştür
    // convertedOfficeNumber = "+" + convertedOfficeNumber.substring(0);
    return convertedOfficeNumber;
}

  render() {
    const {title, office_number, phone_number, facebook, instagram, location, id } = this.props
    return (
      <div>
        <div  className=' container pt-[52px] w-full pb-[18px] justify-center flex items-center '>
          <h2 className='text-[40px] font-[600] text-[#313e3b] text-center  max-[350px]:text-[30px] leading-[51.2px]
          max-[800px]:text-[28px] max-[800px]:leading-[38px] max-[800px]:pb-[10px]'>
          {title}
          </h2>
        </div>

       
        <div className='flex items-center overflow-y-auto p-[20px] max-[600px]:pl-[70px] justify-center gap-[20px] mt-[12px] max-[1255px]:grid-cols-4 max-[1075px]:grid-cols-3 max-[700px]:grid-cols-2 max-[520px]:grid-cols-1'>
          <a href={`tel:${ id === 8 || id === 29 || id === 33 || id === 13? this.convertedNumber(phone_number) : this.convertedOfficeNumber(office_number)}`} target='_blank' className='flex ml-[30px] items-center gap-[12px] bg-[var(--white)] p-[20px] rounded-[8px] shadow-[0px_0px_12px_0px_#00000030]'>
            <Phone2Svg width='32' height='32' fill='var(--blue)' />
          </a>

          <a href={`https://wa.me/${this.convertedNumber(phone_number)}`} target='_blank' className='flex items-center gap-[12px] bg-[var(--white)] p-[20px] rounded-[8px] shadow-[0px_0px_16px_0px_#00000030]'>
            <WhatsappSvg width='32' height='32' fill='#25D366'/>
          </a>
          
          <a href={facebook} target='_blank' className='flex items-center gap-[12px] bg-[var(--white)] p-[20px] rounded-[8px] shadow-[0px_0px_16px_0px_#00000030]'>
            <FacebookSvg width='32' height='32' fill='#4267B2' />
          </a>
          
          

          <a href={`${instagram}`} target='_blank' className='flex items-center gap-[12px] bg-[var(--white)] p-[16px] rounded-[8px] shadow-[0px_0px_16px_0px_#00000030]'>
            <Instagram width='40' height='40' fill='#DD2A7B' />
          </a>

          <a href={location} target='_blank' className='flex items-center gap-[12px] bg-[var(--white)] p-[20px] rounded-[8px] shadow-[0px_0px_16px_0px_#00000030]'>
            <LocationSvg width='32' height='32' fill='var(--orange)' />
          </a>
        </div>
      </div>
      
      
    )
  }
}


export default Card9
