import React from 'react';

const StarRating = ({ rating }) => {
  const totalStars = 5;
  const filledStars = Math.round(rating);
  const stars = [];

  for (let i = 1; i <= totalStars; i++) {
    if (i <= filledStars) {
      stars.push(<span key={i} className="text-[var(--orange)] text-[20px]">&#9733;</span>); // Dolu yıldız
    } else {
      stars.push(<span key={i} className="text-gray-400  text-[20px]">&#9734;</span>); // Boş yıldız
    }
  }

  return <div>{stars}</div>;
};

export default StarRating;