import React, { Component } from 'react';
import SectionHeader from '../components/SectionHeader';
import Card1 from '../components/cards/Card1';
import { connect } from 'react-redux';
import StudentReviews from '../components/StudentReviews';
import { getExamsData } from '../actions/MainAction';
import { Helmet } from 'react-helmet';

const examPaths = {
  1: '/ielts-imtahani',
  2: '/toefl-imtahani',
  3: '/sat-qebul-imtahani',
  4: '/gre-imtahani',
  6: '/duolingo-imtahani',
  9: '/gmat-imtahani',

};

export class Exams extends Component {
  componentDidMount() {
    const savedLanguage = localStorage.getItem('language');

    // Eğer kaydedilmiş bir dil varsa, onu kullan
    if (savedLanguage) {
      this.props.getExamsData(savedLanguage); // savedLanguage'yi lang_id olarak kullan
    } else {
      this.props.getExamsData(this.props.lang_id); // varsayılan lang_id
    }
    window.scrollTo(0, 0);
  }

  render() {
    const { contentData, examsData } = this.props;
    // console.log(examsData);

    return (
      <div>
        <Helmet>
          <title>İmtahanlar | CELT Colleges</title>
          <meta name="description" content="Rəsmi ingilis dili imtahanlarına bizimlə hazırlaşın! IELTS, TOEFL, SAT imtahanlarında uğurlu nəticə üçün, CELT tədris komandası sizinlədir." />
          <meta name="keywords" content="rəsmi ingilis dili imtahanları, IELTS hazırlıq, TOEFL hazırlıq, SAT hazırlıq, CELT Colleges, ingilis dili imtahanları, imtahan hazırlıq, uğurlu nəticələr" />

          <meta property="og:locale" content="az_AZ" />
          <meta property="og:type" content="article" />
          <meta property="og:title" content="İmtahanlar | CELT Colleges" />
          <meta property="og:description" content="Rəsmi ingilis dili imtahanlarına bizimlə hazırlaşın! IELTS, TOEFL, SAT imtahanlarında uğurlu nəticə üçün, CELT tədris komandası sizinlədir." />
          <meta property="og:url" content="https://celt.az/imtahanlar/" />
          <meta property="og:site_name" content="CELT Colleges" />
          <meta property="article:publisher" content="https://www.facebook.com/celtscollege" />
          <meta property="article:modified_time" content="2022-08-09T09:37:05+00:00" />
          <meta property="og:image" content="https://celt.az/static/media/celtLogo.a95db1d2d66058755538.png" />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@celtcolleges" />
          <meta name="twitter:label1" content="Est. reading time" />
          <meta name="twitter:data1" content="4 minutes" />
        </Helmet>
        <div className='pb-[32px]'>
          <SectionHeader title={contentData?.language_exam} />
          <section className='container grid grid-cols-3 gap-[32px] justify-center pt-[52px] max-[1100px]:gap-[26px] max-[900px]:grid-cols-2 max-[600px]:grid-cols-1'>
            {
              examsData.filter(x => x.section_id == 1)?.map((data, i) => {
                const path = examPaths[data.id] || `/exams/${data.id}`;
                return (
                  <Card1
                    cardphoto='1'
                    cardsinfo='1'
                    btn={contentData?.show_more}
                    price='50'
                    key={i}
                    title={data?.translate?.title}
                    text={data?.translate?.text}
                    image={data?.image_full_url}
                    path={path}
                    lessons={data?.lessons}
                    student_count={data?.student_count}
                  />
                )
              })
            }
          </section>
        </div>

        <div className='pb-[32px]'>
          <SectionHeader title={contentData?.enter_exam} />
          <section className='container grid grid-cols-3 gap-[32px] justify-center pt-[52px] max-[1100px]:gap-[26px] max-[900px]:grid-cols-2 max-[600px]:grid-cols-1'>
            {
              examsData.filter(x => x.section_id == 2)?.map((data, i) => {
                const path = examPaths[data.id] || `/exams/${data.id}`;
                return (
                  <Card1
                    cardphoto='1'
                    cardsinfo='1'
                    btn={contentData?.show_more}
                    price='50'
                    key={i}
                    title={data?.translate?.title}
                    text={data?.translate?.text}
                    image={data?.image_full_url}
                    path={path}
                    lessons={data?.lessons}
                    student_count={data?.student_count}
                  />
                )
              })
            }
          </section>
        </div>

        <div className='mt-[52px] pb-[32px] bg-[#f9fbfb] '>
          <SectionHeader title={contentData?.students_say} />
          <StudentReviews grid_cols='grid-cols-3' />
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  examsData: state.Data.examsData,
  lang_id: state.Data.lang_id,
  contentData: state.Data.contentData,
});

const mapDispatchToProps = { getExamsData };

export default connect(mapStateToProps, mapDispatchToProps)(Exams);
