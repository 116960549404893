import React, { Component } from 'react'

export class RightArrowSvg extends Component {
  render() {
    const {width, height,  fill} = this.props;
    return (
        
          <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 12h14m0 0-6-6m6 6-6 6"
      />
    </svg>
        
       
    )
  }
}

export default RightArrowSvg