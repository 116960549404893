import React, { Component } from 'react';
import school from '../../images/school.jpg'
import LocationSvg from '../svg/LocationSvg';
import FacebookSvg from '../svg/FacebookSvg';
import Instagram from '../svg/Instagram';

import WhatsappSvg from '../svg/WhatsappSvg';
import Phone2Svg from '../svg/Phone2Svg';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

export class Card8 extends Component {
    convertedNumber(phoneNumber = "") {
        let convertedNumber = phoneNumber?.replace(/\D/g, ''); // \D matches any non-digit character
        convertedNumber = "+994" + convertedNumber?.substring(1); // Removing the leading zero
        return convertedNumber
    }





    convertedOfficeNumber(office_number = "") {
        // office_number'ın null veya undefined olmadığından emin olun
        if (!office_number) {
            office_number = "";
        }
        // Replace işlemiyle sadece rakamları al
        let convertedOfficeNumber = office_number.replace(/\D/g, '');
        // + işareti ekleyerek numarayı dönüştür
        // convertedOfficeNumber = "+" + convertedOfficeNumber.substring(0);
        return convertedOfficeNumber;
    }


    getLangCode = () => {
        const langId = localStorage.getItem('language'); // Dili buradan alıyoruz
        return langId === '1' ? '' : '/en';
    };

    render() {
        const { title, phone_number, office_number, location, facebook, instagram, id,
            contentData, image_full_url = "", path = ""
        } = this.props

        const langCode = this.getLangCode(); // Dinamik dil kodunu al
        return (

            <div className='rounded-[8px] shadow bg-[var(--white)] border-[1px] '>
                {/* {
               image_full_url !== "" ?(
                <img className='w-full h-[206px] rounded-[8px_8px_0px_0px]' src={image_full_url} />

               ) :(
               <img className='w-full h-[206px] rounded-[8px_8px_0px_0px]' src={school} />

            )} */}
                {/* <img className='w-full h-[206px] rounded-[8px_8px_0px_0px]' src={image_full_url} /> */}
                <div className=' flex flex-col  gap-[16px] p-[16px]'>
                    <div className='w-full flex justify-center'>
                        <h3 className='font-[500] h-[45px]   text-center text-[20px] text-[black] max-[800px]:min-h-[55px] max-[850px]:text-[18px]'>
                            {title}
                        </h3>
                    </div>


                    <div className='flex justify-center w-full'>
                        <Link to={`${langCode}${path}`}>
                            <button className='bg-[var(--blue)] px-[30px] h-[35px] text-white font-semibold rounded-[50px]'>
                                {contentData?.show_btn}
                            </button>
                        </Link>
                    </div>




                    {/* <div className='flex justify-center gap-[16px] '>

                    <div className='p-[3px] bg-[#E5F0FF] rounded-[50%]'>
                        <a href={instagram} target='_blank'>
                            <Instagram width='20' height='20' fill='var(--blue)' />
                        </a>
                    </div>  
                    
    
                    <div className='p-[3px] bg-[#E5F0FF] rounded-[50%]'>
                        <a href={facebook} target='_blank'>
                            <FacebookSvg  width='20' height='20' fill='var(--blue)'/>
                        </a>
                    </div>
                    
                    <div className='p-[3px] bg-[#E5F0FF] rounded-[50%]'>
                        <a href={location} target='__blank'>
                            <LocationSvg width='20' height='20' fill='var(--blue)'  />
                        </a>
                    </div>
                    
                    
                </div> */}
                    {/* <div className='flex items-center justify-between gap-[18px]  '>
                    <button  className='flex items-center justify-center border-[1px] border-[var(--blue)] w-1/2 rounded-[12px] p-[4px] gap-[4px]'>
                     
                        <a  href={`tel:${ id === 8 || id === 29 || id === 33 ? this.convertedNumber(phone_number) : this.convertedOfficeNumber(office_number)}`}  target='_blank' id='officenumber'  className='flex gap-[2px] items-center'>
                            <span className='rounded-[50%] p-[4px] bg-[#E5F0FF]'>
                                <Phone2Svg width='14px' height='14px' fill='var(--blue)' />
                            </span>
                            <span className='text-[11px] text-[#3E3B3B] font-[14px]'>
                                {contentData?.card8_call}
                            </span>
                        </a>
                    </button>
                    
                    <button className='flex items-center justify-center border-[1px] border-[var(--blue)] w-1/2 rounded-[12px] p-[4px] gap-[6px]'>
                        <a href={`https://wa.me/${this.convertedNumber(phone_number)}`} target='_blank'  className='flex gap-[2px] items-center'>
                            <span className='rounded-[50%] p-[4px] bg-[#E5F0FF]'>
                                <WhatsappSvg fill='var(--blue)' width='14px' height='14px'/>
                            </span>
                            <span className='text-[11px] text-[#3E3B3B] font-[14px]'>
                                {contentData?.card8_write_message }
                            </span>
                        </a>
                    </button>
                </div> */}


                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    contentData: state.Data.contentData,
    lang_id: state.Data.lang_id
})

export default connect(mapStateToProps)(Card8)